import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './ApplicationSubmission.module.css';
import Input from '../../../components/PhoneInput/Input';
import sendImage from '../../../assets/send_white.png';
import company_logo from '../../../assets/riyad-bank-logo.png';
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';

import countDown from '../../Application/Utils/CountDown/CountDown';


class ApplicationSubmission extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        /****** Start Phone Element  ********/
        application: {
            formData: {
                'phone': {
                    ar_inputLabel: "رقم الهاتف",
                    en_inputLabel: "phone",
                    has_error: null,
                    inputDisabled: false,
                    inputName: "phone",
                    inputRequired: true,
                    inputToolTip: "Please enter phone",
                    randomDisabledID: null,
                    randomRequiredID: null,
                    value: '',
                    type: "phone"
                }
            }
        },
        
        codeSent: false,
        codeResent: false,
        validPhone: true,
        countDown: 0,
        vCode: "",
        phone: "",
        validCode: true,
        submitError: false,
        triesForPhone: 0,
        triesForAccount: 0,
        requests: [],
        /******* End Phone Element *******/

        fb_id: null,
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        cities: [],
        phoneVerified:false,
        phoneValid:true,
        formSubmitted: false,
        hide_other_city: 'd-none',
        type: null,
        error_code: 200,
        error_message: "حدث خطأ حاول مره اخري",
        error_messages: {
            400: "حدث خطأ حاول مره اخري",
            404: "الرابط المطلوب غير موجود",
            422: "تخطيت الحد المسموح يرجى إعادة التجربة بعد ساعة",
            502: "حدث خطأ في ارسال كود التفعيل",
        },

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        values: {
            form_id: 1,
            type_id: 1,
            type_en: 'Application',
            type_ar: 'طلب تمويل',
            name: '',
            phone: '',
            governorate_id: '',
            city_id: '',
            other_city: '',
            project: '',
            fund: '',
            details: ''
        }
    }

    componentDidMount() {
        document.title = 'Application Submission';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        const fund_type = queryParams.get('fund_type');
        const platform = queryParams.get('platform')
        const fund_types = {
            "تمويل التروسيكل": "تمويل التروسيكل",
            "تمويل صغير جدا": "51000-100000",
            "تمويل متناهي الصغر": "5000-50000"
        }
        if(fund_type){
            // console.log(fund_type, fund_types[fund_type]);
            this.setState({
                values: {
                    ...this.state.values,
                    fund: fund_types[fund_type]
                }
            });
        }

        this.setState({ fb_id: fb_id, bot: bot, type: type });
        axios.get(process.env.REACT_APP_API_URL + '/governorates/get').then((response) => {
            this.setState({ governorates: response.data.data })
        }).catch(error => { });
        // axios.get(process.env.REACT_APP_API_URL + '/settings/get?fb_id=' + fb_id).then((response) => {
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " " || platform === 'whatsapp'){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers})
        }).catch(error => { });
    }


    onInputChanged = (event) => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
        if(event.target.name === 'governorate_id'){
            axios.get(process.env.REACT_APP_API_URL + '/cities/get/' + event.target.value).then((response) => {
                response.data.data.push({
                    governorate_id: 0,
                    id: 0,
                    name_ar: "أخرى ...",
                    name_en: "Others"
                });
                this.setState({ cities: response.data.data })
            }).catch(error => { });
        }
        if(event.target.name === 'phone'){
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
        }
        if(event.target.name === 'city_id'){
            if(event.target.value === '0') 
                this.setState({values: {...this.state.values, city_id: undefined}, hide_other_city: ''});
            else
                this.setState({hide_other_city: 'd-none'});
        }
    }

    

    formSubmitHandler = (event) => {
        event.preventDefault();
        this.state.phoneVerified  = true;
        if (this.state.phoneVerified) {
            this.setState({ loading: true });
            this.setState({phoneValid:true});
            axios.post(process.env.REACT_APP_BOT_V2 + '/whatsapp/demo', {
                fb_id: this.state.fb_id,
                type: 'demo',
                bot_name: 'riyad',
            }).then((res) => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);
    
            }).catch(error => {
                this.setState({ loading: false, formSubmitted: true });
                // this.setState({ loading: false, error: true });
            })
        }
        else {
            this.setState({ validPhone: false });
        }
    }

    /**
     * Start Phone Verification Process
     */
    phoneVerifyHandler = (element) => {
        // let application = { ...this.state.application };
        // let phone = application.formData[element.inputName];
        let phone = this.state.values.phone;
        
        if (this.state.countDown === 0) {
            if (this.isValidPhone(phone)) {
                this.sendPhoneNumber(phone);
            }
            else {
                this.setState({ validPhone: false });
            }
        }
    }
    
    inputChangedHandler = (element, event) => {
        let application = { ...this.state.application };
        let value = event.target.value;
        if (element.type === "phone") {
            let numbers = value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
                console.log(element);
            });
            application.formData.phone.value = phone_str

            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
            // this.setState({
            //     values: {
            //         ...this.state.values,
            //         phone: value
            //     }
            // })
            
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
            // this.setState({ phoneVerified: false, codeSent: false })
        }
        // application.formData[element.inputName] = value;
        this.setState({ application: application });
    }

    isValidPhone(phone) {
        var regex = /^\d+$/;
        return phone && phone.startsWith("01") && phone.length <= 14 && phone.length >= 11 && phone.match(regex);
    }

    sendPhoneNumber = (phone) => {
        const NOW = new Date();
        let requests = [...this.state.requests];

        let lastRequestsForAccount = this.getLastRequestsOfAccount(requests);

        if (lastRequestsForAccount.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequestsForAccount)
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        else {
            let lastRequestsForPhone = lastRequestsForAccount.filter((request) => {
                return phone === request.phone
            });

            if (lastRequestsForPhone.length) {
                let lastRequestTime = this.getLastRequestTime(lastRequestsForPhone);
                const differnce = Math.floor((NOW - lastRequestTime) / 1000);
                switch (lastRequestsForPhone.length) {
                    case 1:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[0], phone, 1);
                        break;
                    case 2:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[1], phone, 2);
                        break;
                    case 3:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[2], phone, 3);
                        break;
                    case 4:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[3], phone, 4);
                        break;
                    case 5:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                    default:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                }
            }
            else {
                this.launchTimer(60);
                this.sendPhoneSMS(phone);
            }

        }
    }

    

    getLastRequestsOfAccount = (requests) => {
        const NOW = new Date();
        return requests.filter((request) => {
            return ((NOW.getTime() - (new Date(request.date).getTime() + (countDown.ONE_HOUR * 2))) < countDown.ONE_HOUR);
        });
    }

    launchTimer = (countDown) => {
        this.setState({ countDown: countDown });
        let countDownInterval = setInterval(
            () => {
                if (this.state.countDown <= 1) {
                    clearInterval(countDownInterval);
                    this.setState({ codeResent: false, countDown: 0 });
                }
                else {
                    this.setState((prevState) => ({
                        countDown: prevState.countDown - 1
                    }));
                }
            },
            1000);
    }

    sendPhoneSMS = (phone) => {
        let params = {
            fb_id: this.state.fb_id,
            phone: phone,
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/send', params).then(response => {
            console.log(response);
            if (response.data.code === 200) {
                this.setState({
                    error: false,
                    codeSent: true,
                    codeResent: true,
                    validPhone: true, phone: phone,
                    // requests: this.filterRequestsForLastHour(response.data.verification_requests)
                });
            }
            else if (response.data.code === 401) {
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0 });
            }
            else {
                this.setState({
                    error: true,
                    error_code: response.data.code,
                    error_message: (response.data.code in this.state.error_messages) ? this.state.error_messages[response.data.code] : this.state.error_message,
                    validPhone: false
                });
            }

        }).catch(error => {
            this.setState({ validPhone: false });
        })
    }

    filterRequestsForLastHour = (requests) => {
        const NOW = new Date();
        let lastRequests = this.getLastRequestsOfAccount(requests);
        this.setState({ triesForAccount: lastRequests.length });
        if (lastRequests.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequests);
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        return lastRequests;
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length === 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    codeChangedHandler = (event) => {
        let code = event.target.value;
        let numbers = code.split("");
        let phone_str = '';
        numbers.forEach(element => {
            if (element in this.state.arabic_numbers)
                element = this.state.arabic_numbers[element];
            phone_str += element;
        });
        code = phone_str;
        if (this.isValidCode(code)) {
            this.setState({ vCode: code });
        }
    }

    isValidCode = (code) => {
        var regex = /^\d*$/;
        if (code === undefined || code === null)
            code = "";
        return code.length <= 4 && code.match(regex);
    }

    sendCode = (code, phone) => {
        phone = this.state.values.phone;
        console.log(phone);
        let params = {
            fb_id: this.state.fb_id,
            code: code,
            phone: phone
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/verify', params).then(response => {
            console.log(response.data.code);
            if(response.data.code === 422){
                this.setState({ validCode: false })    
            }else{
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0, vCode: "" });
            }
        }).catch(error => {
            this.setState({ validCode: false })
        });
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length === 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    /**
     * Render Phone Input Components
     */
    renderPhoneInput = () => {
        var inputProps = {
            key: "phone",
            element: {
                ar_inputLabel: "رقم الهاتف",
                en_inputLabel: "phone",
                has_error: null,
                inputDisabled: false,
                inputName: "phone",
                inputRequired: true,
                inputToolTip: "Please enter phone",
                randomDisabledID: null,
                randomRequiredID: null,
                type: "phone"
            },
            changed: this.inputChangedHandler,
            value: this.state.application.formData['phone'].value,
            
            phoneVerify: this.phoneVerifyHandler,
            codeSent: this.state.codeSent,
            validPhone: this.state.validPhone,
            countDown: this.state.countDown,
            codeResent: this.state.codeResent,
            codeVerify: this.codeVerifyHandler,
            codeChanged: this.codeChangedHandler,
            vCode: this.state.vCode,
            validCode: this.state.validCode,
            phoneVerified: this.state.phoneVerified,
        }
        return (<Input {...inputProps} />);
    }

    /**
     * END Phone Verification Process
     */
    render() {
        const phoneInput = this.renderPhoneInput();
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", " و هنتواصل معاك في خلال ٤٨ ساعة"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج تقديم علي تمويل شخصي من بنك الرياض'}  company_logo={company_logo}/>
                            </header>
                            {this.state.error && <p className={classes.Error}>{this.state.error_message}</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="رقم بطاقة الهوية الوطنية / الإقامة" required name="national_id" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select  required name='nationality'>
                                                <option selected="selected" value="">إختر الجنسية</option>
                                                <option value="166">(جزيرة كوكوس( كيلنج</option>
                                                <option value="031">أذربيجان</option>
                                                <option value="051">أرمينيا</option>
                                                <option value="232">أريتيريا</option>
                                                <option value="036">أستراليا</option>
                                                <option value="004">أفغانستان</option>
                                                <option value="008">ألبانيا</option>
                                                <option value="016">أمريكا ساموا</option>
                                                <option value="010">أنتراكيتا</option>
                                                <option value="028">أنتيغوا وباربودا</option>
                                                <option value="020">أندورا</option>
                                                <option value="024">أنغولا</option>
                                                <option value="660">أنغويلا</option>
                                                <option value="231">اثيوبيا</option>
                                                <option value="533">اروبا</option>
                                                <option value="724">اسبانيا</option>
                                                <option value="233">استونيا</option>
                                                <option value="218">اكوادور</option>
                                                <option value="643">الاتحاد السوفيتي</option>
                                                <option value="032">الارجنتين</option>
                                                <option value="400">الاردن</option>
                                                <option value="784">الامارات العربية المتحدة</option>
                                                <option value="048">البحرين</option>
                                                <option value="076">البرازيل</option>
                                                <option value="620">البرتوغال</option>
                                                <option value="044">البهاما</option>
                                                <option value="070">البوسنة والهرسك</option>
                                                <option value="012">الجزائر</option>
                                                <option value="850">الجزر العذراء الأمريكية</option>
                                                <option value="092">الجزر العذراء البريطانية</option>
                                                <option value="434">الجماهيرية العربية الليبية</option>
                                                <option value="760">الجمهورية العربية السورية</option>
                                                <option value="203">الجمهورية تشيك</option>
                                                <option value="222">السلفادور</option>
                                                <option value="736">السودان</option>
                                                <option value="752">السويد</option>
                                                <option value="706">الصومال</option>
                                                <option value="368">العراق</option>
                                                <option value="414">الكويت</option>
                                                <option value="280">المانيا</option>
                                                <option value="348">المجر</option>
                                                <option value="504">المغرب</option>
                                                <option value="260">المقاطعات الجنوبية الفرنسية</option>
                                                <option value="086">المقاطعات الهندية البريطانية</option>
                                                <option value="484">المكسيك</option>
                                                <option value="826">المملكة المتحدة</option>
                                                <option value="578">النروج</option>
                                                <option value="040">النمسا</option>
                                                <option value="562">النيجر</option>
                                                <option value="356">الهند</option>
                                                <option value="840">الولايات المتحدة</option>
                                                <option value="392">اليابان</option>
                                                <option value="360">اندونسيا</option>
                                                <option value="858">اوروغوي</option>
                                                <option value="860">اوزباكسان</option>
                                                <option value="800">اوغاندا</option>
                                                <option value="804">اوكرانيا</option>
                                                <option value="364">ايران</option>
                                                <option value="372">ايرلندا</option>
                                                <option value="352">ايسلندا</option>
                                                <option value="380">ايطاليا</option>
                                                <option value="598">بابوا غينيا الجديدة</option>
                                                <option value="600">باراغوي</option>
                                                <option value="052">باربادوس</option>
                                                <option value="586">باكيستان</option>
                                                <option value="585">بالاو</option>
                                                <option value="591">باناما</option>
                                                <option value="999">بدون جنسية</option>
                                                <option value="060">برمودا</option>
                                                <option value="096">بروناي دار السلام</option>
                                                <option value="108">بروندي</option>
                                                <option value="056">بلجيكا</option>
                                                <option value="100">بلغاريا</option>
                                                <option value="084">بليز</option>
                                                <option value="050">بنجلاديش</option>
                                                <option value="204">بنين</option>
                                                <option value="064">بوتان</option>
                                                <option value="072">بوتسوانا</option>
                                                <option value="630">بورتو ريكو</option>
                                                <option value="854">بوركينافاسو</option>
                                                <option value="616">بولاندا</option>
                                                <option value="068">بوليفيا</option>
                                                <option value="258">بولينيزيا الفرنسية</option>
                                                <option value="612">بيتكيرن</option>
                                                <option value="604">بيرو</option>
                                                <option value="112">بيلاروسيا</option>
                                                <option value="762">تاجيكستان</option>
                                                <option value="158">تايوان</option>
                                                <option value="796">تركمانستان</option>
                                                <option value="792">تركيا</option>
                                                <option value="780">ترينيداد وتوباغو</option>
                                                <option value="148">تشاد</option>
                                                <option value="152">تشيلي</option>
                                                <option value="834">تنزانيا</option>
                                                <option value="768">توغو</option>
                                                <option value="798">توفالو</option>
                                                <option value="772">توكيلو</option>
                                                <option value="788">تونس</option>
                                                <option value="776">تونغا</option>
                                                <option value="764">تيلاند</option>
                                                <option value="626">تيمور الشرق</option>
                                                <option value="388">جامايكا</option>
                                                <option value="304">جرين لاند</option>
                                                <option value="831">جرينزي</option>
                                                <option value="530">جزر الأنتيل الهولندية</option>
                                                <option value="234">جزر الفارو</option>
                                                <option value="238">جزر الفوكلاند</option>
                                                <option value="174">جزر القمر</option>
                                                <option value="136">جزر الكايمان</option>
                                                <option value="581">جزر الولايات المتحدة البعيدة ا</option>
                                                <option value="090">جزر سليمان</option>
                                                <option value="184">جزر كوك</option>
                                                <option value="132">جزر كيب فيرد</option>
                                                <option value="584">جزر مارشال</option>
                                                <option value="580">جزر ماريانا الشمالية</option>
                                                <option value="876">جزر والس وفوتونا</option>
                                                <option value="162">جزيرة الكريسماس</option>
                                                <option value="074">جزيرة بوفيه</option>
                                                <option value="833">جزيرة مان</option>
                                                <option value="574">جزيرة نورفوك</option>
                                                <option value="334">جزيرة هيرد وماكدونالد</option>
                                                <option value="140">جمهورية أفريقيا الوسطى</option>
                                                <option value="214">جمهورية الدومنيكلن</option>
                                                <option value="410">جمهورية كوريا</option>
                                                <option value="807">جمهورية مقدونيا اليوغوسلافية ا</option>
                                                <option value="710">جنوب أفريقيا</option>
                                                <option value="268">جورجيا</option>
                                                <option value="239">جورجيا الجنوبية وجزر ساندويتش</option>
                                                <option value="262">جيبوتي</option>
                                                <option value="832">جيرزي</option>
                                                <option value="208">دنمارك</option>
                                                <option value="336">دولة الفاتيكان</option>
                                                <option value="212">دومينيكا</option>
                                                <option value="646">رواندا</option>
                                                <option value="642">رومانيا</option>
                                                <option value="638">ريونيون</option>
                                                <option value="894">زانبيا</option>
                                                <option value="716">زنبابوي</option>
                                                <option value="384">ساحل العاج</option>
                                                <option value="882">ساموا</option>
                                                <option value="666">سان بيار وميكلون</option>
                                                <option value="674">سان مارينو</option>
                                                <option value="670">سانت فنسنت وغرينادين</option>
                                                <option value="659">سانت كيتس ونيفيس</option>
                                                <option value="662">سانت لوسيا</option>
                                                <option value="654">سانت هيلينا</option>
                                                <option value="678">ساو تومي وبرينسيبي</option>
                                                <option value="144">سريلانكا</option>
                                                <option value="744">سفالبارد وجان ماين</option>
                                                <option value="703">(سلوفاكيا (جمهورية سلوفاكيا</option>
                                                <option value="705">سلوفينيا</option>
                                                <option value="748">سوازيلند</option>
                                                <option value="740">سورينام</option>
                                                <option value="756">سويسرا</option>
                                                <option value="694">سيراليون</option>
                                                <option value="690">سيشل</option>
                                                <option value="702">سينغابور</option>
                                                <option value="686">سينيغال</option>
                                                <option value="156">صين</option>
                                                <option value="512">عمان</option>
                                                <option value="266">غابون</option>
                                                <option value="270">غامبيا</option>
                                                <option value="288">غانا</option>
                                                <option value="308">غرناطا</option>
                                                <option value="320">غواتيمالا</option>
                                                <option value="312">غواديلوب</option>
                                                <option value="316">غوام</option>
                                                <option value="254">غوانا الفرنسية</option>
                                                <option value="328">غويانا</option>
                                                <option value="324">غينيا</option>
                                                <option value="226">غينيا الاستوائية</option>
                                                <option value="548">فانواتو</option>
                                                <option value="250">فرنسا</option>
                                                <option value="275">فلسطيني</option>
                                                <option value="246">فنلندا</option>
                                                <option value="704">فيتنام</option>
                                                <option value="242">فيجي</option>
                                                <option value="608">فيليبين</option>
                                                <option value="862">فينيزولا</option>
                                                <option value="196">قبرس</option>
                                                <option value="634">قطر</option>
                                                <option value="417">قيرغيزستان</option>
                                                <option value="398">كازخستان</option>
                                                <option value="540">كاليدونيا الجديدة</option>
                                                <option value="116">كامبوديا</option>
                                                <option value="120">كاميرون</option>
                                                <option value="191">كرواتيا</option>
                                                <option value="124">كندا</option>
                                                <option value="188">كوستاريكا</option>
                                                <option value="170">كولومبيا</option>
                                                <option value="178">كونغو</option>
                                                <option value="296">كيريباتي</option>
                                                <option value="404">كينيا</option>
                                                <option value="428">لاتفيا</option>
                                                <option value="418">لاوس</option>
                                                <option value="422">لبنان</option>
                                                <option value="442">لوكسمبورغ</option>
                                                <option value="430">ليبيريا</option>
                                                <option value="440">ليتوانيا</option>
                                                <option value="438">ليختنشتاين</option>
                                                <option value="426">ليسوتو</option>
                                                <option value="450">ماداغسكر</option>
                                                <option value="474">مارتينيك</option>
                                                <option value="446">ماكاو</option>
                                                <option value="470">مالتا</option>
                                                <option value="466">مالي</option>
                                                <option value="458">ماليزيا</option>
                                                <option value="175">مايوت</option>
                                                <option value="818">مصر</option>
                                                <option value="454">ملاوي</option>
                                                <option value="462">ملديف</option>
                                                <option value="478">موريتانيا</option>
                                                <option value="508">موزانبيك</option>
                                                <option value="498">مولدافيا</option>
                                                <option value="492">موناكو</option>
                                                <option value="500">مونتسرات</option>
                                                <option value="496">مونغوليا</option>
                                                <option value="104">ميانمار</option>
                                                <option value="583">ميكرونيزيا</option>
                                                <option value="520">ناورو</option>
                                                <option value="516">نميبيا</option>
                                                <option value="524">نيبال</option>
                                                <option value="566">نيجريا</option>
                                                <option value="558">نيكاراغوا</option>
                                                <option value="554">نيو زيلاندا</option>
                                                <option value="570">نيوي</option>
                                                <option value="332">هايتي</option>
                                                <option value="528">هولندا</option>
                                                <option value="344">هون كونغ</option>
                                                <option value="340">هوندورس</option>
                                                <option value="887">يمن</option>
                                                <option value="891">يوغوزلافيا</option>
                                                <option value="300">يونان</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="العائلة" required name="family" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="تاريخ الميلاد" required name="birthday" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder=" رقم الجوال" required name="phone" value={this.state.fb_id} disabled/>
                                        </div>
                                    </div>

                                    {/* {phoneInput}

                                    {!this.state.phoneValid && <p style={{color:'Red'}}>رقم التليفون غير صحيح</p>} */}


                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input type="email"  placeholder=" البريد الإلكتروني" required name="email" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder=" الراتب الشهري" required name="salary" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="الالتزامات الشهرية - بالريال" required name="liabilities" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select  required  name='nationality'>
                                                <option selected="selected" value="">نوع الراتب</option>
                                                <option value="">تحويل راتب</option>
                                                <option value="">بدون تحويل راتب</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input placeholder="المدينة" required name="city" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select  required name='region'>
                                                <option selected="selected" value="">اختر</option>
                                                <option value="1-Central">الوسطى</option>
                                                <option value="3-Eastern">الشرقية</option>
                                                <option value="2-Western">الغربية</option>
                                                <option value="4-Southern">الجنوبية</option>
                                                <option value="5-Northern">الشمالية</option>
                                            </select>
                                            
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select name='business_sector'>
                                                <option selected="selected" value="">قطاع العمل</option>
                                            </select>
                                            
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select required name='months'>
                                                <option selected="selected" value="">فترة التمويل - عدد الشهور</option>
                                                <option value="12">12</option>
                                                <option value="24">24</option>
                                                <option value="36">36</option>
                                                <option value="48">48</option>
                                                <option value="60">60</option>
                                            </select>
                                            
                                        </div>
                                    </div>

                                </div>
                                <footer>


                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />
                        تأكيد
                    </button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default ApplicationSubmission;