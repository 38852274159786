import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './Suggestion.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';




class Suggestion extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        fb_id: null,
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        branches: [],//["اخميم", "ارمنت", "اسنا", "الابراهيمية", "البلينا", "الحسينية", "المنشأه", "الوقف", "ببا", "سمالوط", "طهطا", "قوص", "كوم امبو", "مغاغة", "ملوي", "منيا القمح", "ناصر", "نجع حمادي", "ههيا",],
        phoneVerified:false,
        phoneValid:true,
        nationalIdValid: false,
        formSubmitted: false,
        hide_other_branch: 'd-none',
        type: null,
        
        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        values: {
            form_id: 3,
            type_id: 3,
            type_en: 'Suggestion',
            type_ar: 'اقتراح',
            name: '',
            phone: '',
            suggestion: '',
            date: Date.now(),
        }
    }

    componentDidMount() {
        document.title = 'Suggestion';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        this.setState({ fb_id: fb_id, bot: bot, type: type });
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers})
        }).catch(error => { });
    }

    onInputChanged = (event) => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
        if(event.target.name === 'phone'){
            let numbers = event.target.value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
            });
            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
        }
    }

    formSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({phoneValid:true});
        if(!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 ||  !/^\d+$/.test(this.state.values.phone)){            
            this.setState({phoneValid:false});
            return;
        }
        this.setState({ loading: true });
        let TAMWEELY_WHATSAPP = '';
        if(this.state.fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.post(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/user-requests/suggestion', {
            fb_id: this.state.fb_id,
            type: this.state.type,
            bot_name: this.state.bot,
            ...this.state.values
        }).then((res) => {
            this.setState({ loading: false, formSubmitted: true });
            let skipInterval = setInterval(
                () => {
                    if (this.state.skipCountdown <= 1) {
                        clearInterval(skipInterval);
                        window.closeWebView();
                    }
                    else {
                        this.setState((prevState) => ({
                            skipCountdown: prevState.skipCountdown - 1
                        }));
                    }
                },
                1000);

        }).catch(error => {
            this.setState({ loading: false, error: true });
        })
    }

    render() {
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", "شكرا جدا لمقتراحاتك ومشاركة رأيك معانا .. سيتم النظر فيه بجديه والتواصل معك في حين احتاجنا تفاصيل اخري"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج تقديم اقتراح "' + this.state.company_name + '" '}  company_logo={this.state.company_logo}/>
                            </header>
                            {this.state.error && <p className={classes.Error}>حدث خطأ حاول مره اخري</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>



                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control " + (this.state.phoneVerified ? "is-valid" : "" )} onChange={this.onInputChanged} maxLength='14' value={this.state.values.phone} type='tel' placeholder="رقم التليفون" required name="phone" />
                                        </div>
                                    </div>
                                    {!this.state.phoneValid && <p style={{color:'Red'}}>رقم التليفون غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize value={this.state.values.suggestion} onChange={this.onInputChanged} placeholder="الإقتراح" name="suggestion" />
                                        </div>
                                    </div>

                                </div>
                                <footer>
                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />تأكيد</button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default Suggestion;