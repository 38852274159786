import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './Career.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';




class Career extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        fb_id: null,
        platform: 'facebook',
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        cities: [],
        phoneVerified:false,
        phoneValid:true,
        formSubmitted: false,
        hide_other_city: 'd-none',
        hide_experience: 'd-none',
        type: null,

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },
        jobs: [],
        hq_jobs: [
            "الشئون الإدارية",
            "الموارد البشرية",
            "مركز الاتصالات وخدمة العملاء",
            "المشروعات",
            "تكنولوجيا المعلومات",
            "المالية",
            "اللوجستية",
            "الأعمال",
            "الفحص  الأئتمانى",
            "المراجعة الداخلية",
            "الهندسية",
            "المخاطر",
            "الشئون القانونية",
            "الامن",
            "إدارة التدريب والتطوير ونظم العمل",
            "تطوير الاعمال",
            "التسويق"
        ],
        other_jobs: [
            "مسئول اول",
            "مسئول تمويل",
            "صراف اول",
            "صراف",
            "فرد امن",
            "مدير فرع"
        ],
        head_quarter: false,

        values: {
            form_id: 2,
            type_id: 2,
            type_en: 'Career',
            type_ar: 'طلب توظيف',
            name: '',
            job: '',
            phone: '',
            governorate_id: '',
            city_id: '',
            prev_experience: '',
            other_city: '',
            details: '',
            email: '',
            company_name: '',
            company_job: '',
            experience_years: ''
        }
    }

    componentDidMount() {
        document.title = 'Careers';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const platform = queryParams.get('platform')
        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        this.setState({ fb_id: fb_id, platform: platform, bot: bot, type: type });
        axios.get(process.env.REACT_APP_API_URL + '/governorates/get').then((response) => {
            this.setState({ governorates: response.data.data })
        }).catch(error => { });
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers})
        }).catch(error => { });
    }


    onInputChanged = (event) => {  
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
        if(event.target.name === 'governorate_id'){
            let head_quarter = event.target[event.target.selectedIndex].getAttribute('data-head-quarter')
            head_quarter = head_quarter == 'true'
            axios.get(process.env.REACT_APP_API_URL + '/cities/get/' + event.target.value).then((response) => {
                response.data.data.push({
                    governorate_id: 0,
                    id: 0,
                    name_ar: "أخرى ...",
                    name_en: "Others"
                });
                console.log(response.data.data);
                if(head_quarter){
                    response.data.data.unshift({
                        governorate_id: '',
                        id: null,
                        name_ar: "المركز الرئيسي",
                        name_en: "Head Quarter"
                    });
                }
                this.setState({ cities: response.data.data })
            }).catch(error => { });
            
            this.setState({head_quarter: head_quarter})
            if(head_quarter)
                this.setState({ jobs: this.state.hq_jobs })
            else
                this.setState({ jobs: this.state.other_jobs })
        }
        if(event.target.name==='email'){
            event.target.setCustomValidity('');
        }     
        if(event.target.name === 'phone'){
            let numbers = event.target.value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
            });
            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
        }
        if(event.target.name === 'city_id'){
            if(event.target.value === '0') 
                this.setState({values: {...this.state.values, city_id: undefined}, hide_other_city: ''});
            else
                this.setState({hide_other_city: 'd-none'});
        }
        if(event.target.name === 'prev_experience'){
            if(event.target.value === '1') 
                this.setState({values: {...this.state.values, prev_experience: '1'}, hide_experience: ''});
            else
                this.setState({hide_experience: 'd-none'});
        }
        
    }

    onInvalidEmail = (event)=>{
        event.target.setCustomValidity("الايميل غير صحيح");
    }

    formSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({phoneValid:true});

        if(!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 ||  !/^\d+$/.test(this.state.values.phone)){            
            this.setState({phoneValid:false});
            return;
        }
        this.setState({ loading: true });
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(this.state.fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.post(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/applications/create', {
            fb_id: this.state.fb_id,
            type: this.state.type,
            bot_name: this.state.bot,
            ...this.state.values
        }).then((res) => {
            this.setState({ loading: false, formSubmitted: true });
            let skipInterval = setInterval(
                () => {
                    if (this.state.skipCountdown <= 1) {
                        clearInterval(skipInterval);
                        window.closeWebView();
                    }
                    else {
                        this.setState((prevState) => ({
                            skipCountdown: prevState.skipCountdown - 1
                        }));
                    }
                },
                1000);

        }).catch(error => {
            this.setState({ loading: false, error: true });
        })
    }

    render() {
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen platform={this.state.platform} countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", "وهيتم التواصل معاك في حين ايجاد فرصة مناسبة "]}/>
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج التقديم لوظيفة "' + this.state.company_name + '" '}  company_logo={this.state.company_logo}/>
                            </header>
                            {this.state.error && <p className={classes.Error}>حدث خطأ حاول مره اخري</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control " + (this.state.phoneVerified ? "is-valid" : "" )} onChange={this.onInputChanged} maxLength='14' value={this.state.values.phone} type='tel' placeholder="رقم التليفون" required name="phone" />
                                        </div>
                                    </div>
                                    {!this.state.phoneValid && <p style={{color:'Red'}}>رقم التليفون غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.governorate_id} name='governorate_id'>
                                                <option value="" disabled >المحافظة</option>
                                                {this.state.governorates.map((governorate) => {
                                                    return <option key={governorate.name_ar} value={governorate.id} data-head-quarter={governorate.head_quarter}>{governorate.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.city_id} name='city_id'>
                                                <option value="" disabled >المركز</option>
                                                {this.state.cities.map((city) => {
                                                    return <option key={city.name_ar} value={city.id} >{city.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"row " + this.state.hide_other_city }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} maxLength='14' value={this.state.values.other_city} type='text' placeholder="اسم المركز" name="other_city" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.job} name='job'>
                                                <option value="" disabled >الوظيفة المطلوبة</option>
                                                {this.state.jobs.map((job) => {
                                                    return <option key={job} value={job}>{job}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize value={this.state.values.details} onChange={this.onInputChanged} placeholder="اضافة تفاصيل" name="details" />
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input value={this.state.values.email} onChange={this.onInputChanged}
                                             type='email' placeholder="البريد الالكتروني" name="email" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} value={this.state.values.prev_experience} name='prev_experience'>
                                                <option value="" disabled >هل لديك خبرة مسبقة في نفس المجال</option>
                                                <option key="1" value="1">نعم</option>
                                                <option key="0" value="0">لا</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"row " + this.state.hide_experience }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.company_name} placeholder="اسم الشركة" name="company_name" />
                                        </div>
                                    </div>


                                    <div className={"row " + this.state.hide_experience }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.company_job} placeholder="الوظيفة" name="company_job" />
                                        </div>
                                    </div>

                                    <div className={"row " + this.state.hide_experience }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.experience_years} placeholder="عدد سنوات الخبرة" name="experience_years" />
                                        </div>
                                    </div>

                                </div>
                                <footer>


                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />
                        تأكيد
                    </button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default Career;