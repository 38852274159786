import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './LoanRequest.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';
import Input from '../../../components/PhoneInput/Input';

import countDown from '../../Application/Utils/CountDown/CountDown';

import data from './data.json'


class LoanRequest extends Component {

    state = {
        // Fill from the settings
        company_name: 'بنك الإمارات دبي الوطني',
        company_logo: '',
        verfied_phone_numbers: [],

        /****** Start Phone Element  ********/
        application: {
            formData: {
                'phone': {
                    ar_inputLabel: "رقم الهاتف",
                    en_inputLabel: "phone",
                    has_error: null,
                    inputDisabled: false,
                    inputName: "phone",
                    inputRequired: true,
                    inputToolTip: "Please enter phone",
                    randomDisabledID: null,
                    randomRequiredID: null,
                    value: '',
                    type: "phone"
                }
            }
        },

        codeSent: false,
        codeResent: false,
        validPhone: true,
        countDown: 0,
        vCode: "",
        phone: "",
        validCode: true,
        submitError: false,
        triesForPhone: 0,
        triesForAccount: 0,
        requests: [],
        /******* End Phone Element *******/

        fb_id: null,
        loading: false,
        skipCountdown: 4,
        bot: null,
        bot_name: null,

        error: false,
        phoneVerified: false,

        phoneValid: true,
        nationalIdValid: false,
        emailValid: true,
        dateValid: true,
        nameValid: true,

        formSubmitted: false,
        type: null,

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        loanTypes: [],
        professions: [],
        governorates: [],
        companyAges: [],
        salaryRanges: [],
        pharmacyAges: [],

        shouldVerify: false,
        userAge: '',
        values: {
            form_id: 8,
            type_id: 8,
            type_en: 'LoanRequest',
            type_ar: 'طلب قرض',
            loanType: "",
            name: "",
            birthday: "",
            userAge: '',
            national_id: '',
            governorate: '',
            email: '',
            profession: '',
            phone: '',
            normalPhone: '',

            officerWorkName: '',
            officerWorkLocation:'',

            professionSubCategory: '',
            professionOtherSubCategory: '',
            salaryRange: '',

            companyGovernorate: '',
            companyEstablishedFrom: '',
            nameIncluded: '',
            companyName: '',

            drStillWorking: '',
            drClinic: '',

            pharmacyEstablishedFrom: '',

            professionOtherLocation: "",
            professionOtherSalary: ""

        }
    }

    componentDidMount() {
        document.title = 'Emirates Loan Request';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        this.setState({ fb_id: fb_id, bot: bot, bot_name: bot, type: type });

        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');

        axios.get(process.env.REACT_APP_API_URL + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers })
        }).catch(error => {
            console.log(error)
        });

        this.setState({
            professions: data.profession, governorates: data.governorate,
            loanTypes: data.loanType, salaryRanges: data.profession[0].salaryRange,
            companyAges: data.profession[1].companyAge, pharmacyAges: data.profession[4].pharmacy_age,

        })
    }

    onInputChanged = (event) => {
        if (event.target.name == 'normalPhone') {
            this.setState({
                shouldVerify: false,
                values: {
                    ...this.state.values,
                    phone: ''
                }
            })
        }
        if (event.target.name == 'birthday') {
            var today = new Date();
            var birthDate = new Date(event.target.value);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (age < 18) {
                this.setState({
                    userAge: age,
                    dateValid: false
                })
            } else {
                this.setState({
                    userAge: age,
                    dateValid: true
                })
            }
        }

        if (event.target.name == 'phone') {
            if (this.state.verfied_phone_numbers.includes(event.target.value)) {
                this.setState({
                    phoneVerified: true,
                });
            } else {
                this.setState({
                    phoneVerified: false,
                });
            }
        }

        if (event.target.name == 'national_id') {
            let numbers = event.target.value.split("");
            let national_id_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                national_id_str += element;
            });
            this.setState({
                values: {
                    ...this.state.values,
                    national_id: national_id_str
                }
            })
            if (event.target.value.length !== 14 || !/^\d+$/.test(event.target.value)) {
                this.setState({ nationalIdValid: false });
            } else {
                this.setState({ nationalIdValid: true });
            }
        }

        if (event.target.name == 'profession') {
            this.setState({
                values: {
                    ...this.state.values,
                    professionSubCategory: '',
                    professionOtherSubCategory: '',
                    salaryRange: '',
                    companyGovernorate: '',
                    companyEstablishedFrom: '',
                    drStillWorking: '',
                    drClinic: '',
                    pharmacyEstablishedFrom: '',
                    professionOtherLocation: '',
                    professionOtherSalary: '',
                }
            })
        }
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })


    }

    formSubmitHandler = (event) => {
        event.preventDefault();
        if (!this.state.shouldVerify) {
            this.setState({ phoneValid: true, emailValid: true, nameValid: true });
            if (!this.state.values.normalPhone.startsWith('01') || this.state.values.normalPhone.length !== 11 || !/^\d+$/.test(this.state.values.normalPhone)) {
                this.setState({ phoneValid: false });
                return;
            }
            if (this.state.values.email !== '' && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.values.email)) {
                this.setState({ emailValid: false });
                return;
            }
            if (!/^[ا-ي ]+$/.test(this.state.values.name)) {
                this.setState({ nameValid: false });
                return;
            }
            if (!this.state.dateValid) {
                return;
            }
            if (this.state.values.national_id.length !== 14 || !/^\d+$/.test(this.state.values.national_id)) {
                this.setState({ nationalIdValid: false });
                return;
            }

            let values = {
                form_id: 8,
                type_id: 8,
                type_en: 'LoanRequest',
                type_ar: 'طلب قرض',
                loan_type: data.loanType[this.state.values.loanType].ar_name,
                name: this.state.values.name,
                birthday: this.state.values.birthday,
                national_id: this.state.values.national_id,
                governorate: data.governorate[this.state.values.governorate].ar_name,
                email: this.state.values.email,
                profession: data.profession[this.state.values.profession].ar_name,
                phone: this.state.values.normalPhone,
                age: this.state.userAge

            }

            if (this.state.values.profession >= 0 && this.state.values.profession <= 4) {
                values.profession_sub_category = data.profession[this.state.values.profession].sub_category[this.state.values.professionSubCategory].ar_name;
                values.profession_other_sub_category = this.state.values.professionOtherSubCategory;

            }

            if (this.state.values.profession == 0) {
                values.salary_range = data.profession[this.state.values.profession].salaryRange[this.state.values.salaryRange].ar_name;
                values.officer_work_name = this.state.values.officerWorkName;
                values.officer_work_location = this.state.values.officerWorkLocation;
            }
            if (this.state.values.profession == 1) {
                values.company_name = this.state.values.companyName;
                values.name_included = this.state.values.nameIncluded == 0 ? true : false;
                values.company_governorate = data.profession[this.state.values.profession].governorate[this.state.values.companyGovernorate].ar_name;
                values.company_established_from = data.profession[this.state.values.profession].companyAge[this.state.values.companyEstablishedFrom].ar_name;
            }
            
            if (this.state.values.profession == 3) {
                if(this.state.values.professionSubCategory == 0 ||
                    this.state.values.professionSubCategory == 1 ||
                    this.state.values.professionSubCategory == 2  )
                    {
                        values.dr_still_working = this.state.values.drStillWorking == 0 ? true: false;
                    }
                else{
                    values.dr_clinic = this.state.values.drClinic == 0 ? true: false;
                }
            }
            if (this.state.values.profession == 4) {
                if(this.state.values.professionSubCategory != 3){
                    values.pharmacy_established_from = data.profession[this.state.values.profession].pharmacy_age[this.state.values.pharmacyEstablishedFrom].ar_name;
                }
            }
            if (this.state.values.profession == 9){
                values.profession_other_sub_category = this.state.values.professionOtherSubCategory;
                values.other_job_location = this.state.values.professionOtherLocation;
                values.other_job_salary = this.state.values.professionOtherSalary;
            }

            if(this.state.values.profession != 1){
                values.name_included = false;
            }
            this.setState({ loading: true });
            axios.post(process.env.REACT_APP_API_URL + '/applications/create', {
                fb_id: this.state.fb_id,
                type: this.state.type,
                bot_name: this.state.bot,
                ...values
            }).then((res) => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);

            }).catch(error => {
                this.setState({ loading: false, error: true });
            })

        }

        else if (this.state.phoneVerified) {
            this.setState({ phoneValid: true, emailValid: true, nameValid: true });
            if (!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 || !/^\d+$/.test(this.state.values.phone)) {
                this.setState({ phoneValid: false });
                return;
            }
            if (this.state.values.email !== '' && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.values.email)) {
                this.setState({ emailVaild: false });
                return;
            }
            if (!/^[ا-ي ]+$/.test(this.state.values.name)) {
                this.setState({ nameValid: false });
                return;
            }
            if (!this.state.dateValid) {
                return;
            }
            if (this.state.values.national_id.length !== 14 || !/^\d+$/.test(this.state.values.national_id)) {
                this.setState({ nationalIdValid: false });
                return;
            }

            let values = {
                form_id: 8,
                type_id: 8,
                type_en: 'LoanRequest',
                type_ar: 'طلب قرض',
                loan_type: data.loanType[this.state.values.loanType].ar_name,
                name: this.state.values.name,
                birthday: this.state.values.birthday,
                national_id: this.state.values.national_id,
                governorate: data.governorate[this.state.values.governorate].ar_name,
                email: this.state.values.email,
                profession: data.profession[this.state.values.profession].ar_name,
                phone: this.state.values.phone,
                age: this.state.userAge

            }

            if (this.state.values.profession >= 0 && this.state.values.profession <= 4) {
                values.profession_sub_category = data.profession[this.state.values.profession].sub_category[this.state.values.professionSubCategory].ar_name;
                values.profession_other_sub_category = this.state.values.professionOtherSubCategory;

            }

            if (this.state.values.profession == 0) {
                values.salary_range = data.profession[this.state.values.profession].salaryRange[this.state.values.salaryRange].ar_name;
                values.officer_work_name = this.state.values.officerWorkName;
                values.officer_work_location = this.state.values.officerWorkLocation;
            }
            if (this.state.values.profession == 1) {
                values.company_name = this.state.values.companyName;
                values.name_included = this.state.values.nameIncluded == 0 ? true : false;
                values.company_governorate = data.profession[this.state.values.profession].governorate[this.state.values.companyGovernorate].ar_name;
                values.company_established_from = data.profession[this.state.values.profession].companyAge[this.state.values.companyEstablishedFrom].ar_name;
            }

            if (this.state.values.profession == 3) {
                if(this.state.values.professionSubCategory == 0 ||
                    this.state.values.professionSubCategory == 1 ||
                    this.state.values.professionSubCategory == 2  )
                    {
                        values.dr_still_working = this.state.values.drStillWorking == 0 ? true: false;
                    }
                else{
                    values.dr_clinic = this.state.values.drClinic == 0 ? true: false;
                }
            }
            if (this.state.values.profession == 4) {
                if(this.state.values.professionSubCategory != 3){
                    values.pharmacy_established_from = data.profession[this.state.values.profession].pharmacy_age[this.state.values.pharmacyEstablishedFrom].ar_name;
                }
            }
            if (this.state.values.profession == 9){
                values.profession_other_sub_category = this.state.values.professionOtherSubCategory;
                values.other_job_location = this.state.values.professionOtherLocation;
                values.other_job_salary = this.state.values.professionOtherSalary;
            }
            
            if(this.state.values.profession != 1){
                values.name_included = false;
            }
            this.setState({ loading: true });
            axios.post(process.env.REACT_APP_API_URL + '/applications/create', {
                fb_id: this.state.fb_id,
                type: this.state.type,
                bot_name: this.state.bot,
                ...values
            }).then((res) => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);

            }).catch(error => {
                this.setState({ loading: false, error: true });
            })
        }
        else {
            this.setState({ validPhone: false });
        }
    }

    /**
     * Start Phone Verification Process
     */
    phoneVerifyHandler = (element) => {
        // let application = { ...this.state.application };
        // let phone = application.formData[element.inputName];
        let phone = this.state.values.phone;

        if (this.state.countDown == 0) {
            if (this.isValidPhone(phone)) {
                this.sendPhoneNumber(phone);
            }
            else {
                this.setState({ validPhone: false });
            }
        }
    }

    inputChangedHandler = (element, event) => {
        let application = { ...this.state.application };
        let value = event.target.value;
        if (element.type == "phone") {
            let numbers = value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
            });
            application.formData.phone.value = phone_str

            this.setState({
                shouldVerify: true,
                values: {
                    ...this.state.values,
                    phone: phone_str,
                    normalPhone: ''
                }
            })
            // this.setState({
            //     values: {
            //         ...this.state.values,
            //         phone: value
            //     }
            // })

            if (this.state.verfied_phone_numbers.includes(event.target.value)
                || event.target.value.startsWith('015')) {
                this.setState({ phoneVerified: true });
            } else {
                this.setState({ phoneVerified: false });
            }
            // this.setState({ phoneVerified: false, codeSent: false })
        }
        // application.formData[element.inputName] = value;
        this.setState({ application: application });
    }

    isValidPhone(phone) {
        var regex = /^\d+$/;
        return phone && phone.startsWith("01") && phone.length <= 14 && phone.length >= 11 && phone.match(regex);
    }

    sendPhoneNumber = (phone) => {
        const NOW = new Date();
        let requests = [...this.state.requests];

        let lastRequestsForAccount = this.getLastRequestsOfAccount(requests);

        if (lastRequestsForAccount.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequestsForAccount)
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        else {
            let lastRequestsForPhone = lastRequestsForAccount.filter((request) => {
                return phone == request.phone
            });

            if (lastRequestsForPhone.length) {
                let lastRequestTime = this.getLastRequestTime(lastRequestsForPhone);
                const differnce = Math.floor((NOW - lastRequestTime) / 1000);
                switch (lastRequestsForPhone.length) {
                    case 1:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[0], phone, 1);
                        break;
                    case 2:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[1], phone, 2);
                        break;
                    case 3:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[2], phone, 3);
                        break;
                    case 4:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[3], phone, 4);
                        break;
                    case 5:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                    default:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                }
            }
            else {
                this.launchTimer(60);
                this.sendPhoneSMS(phone);
            }

        }
    }



    getLastRequestsOfAccount = (requests) => {
        const NOW = new Date();
        return requests.filter((request) => {
            return ((NOW.getTime() - (new Date(request.date).getTime() + (countDown.ONE_HOUR * 2))) < countDown.ONE_HOUR);
        });
    }

    launchTimer = (countDown) => {
        this.setState({ countDown: countDown });
        let countDownInterval = setInterval(
            () => {
                if (this.state.countDown <= 1) {
                    clearInterval(countDownInterval);
                    this.setState({ codeResent: false, countDown: 0 });
                }
                else {
                    this.setState((prevState) => ({
                        countDown: prevState.countDown - 1
                    }));
                }
            },
            1000);
    }

    sendPhoneSMS = (phone) => {
        let params = {
            fb_id: this.state.fb_id,
            phone: phone,
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/send', params).then(response => {
            if (response.data.code == 200) {
                this.setState({
                    error: false,
                    codeSent: true,
                    codeResent: true,
                    validPhone: true, phone: phone,
                    // requests: this.filterRequestsForLastHour(response.data.verification_requests)
                });
            }
            else if (response.data.code == 401) {
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0 });
            }
            else {
                console.log('FAILED');
                this.setState({
                    error: true,
                    error_code: response.data.code,
                    error_message: (response.data.code in this.state.error_messages) ? this.state.error_messages[response.data.code] : this.state.error_message,
                    validPhone: false
                });
            }

        }).catch(error => {
            console.log(error);
            this.setState({ validPhone: false });
        })
    }

    filterRequestsForLastHour = (requests) => {
        const NOW = new Date();
        let lastRequests = this.getLastRequestsOfAccount(requests);
        this.setState({ triesForAccount: lastRequests.length });
        if (lastRequests.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequests);
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        return lastRequests;
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length == 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    codeChangedHandler = (event) => {
        let code = event.target.value;
        let numbers = code.split("");
        let phone_str = '';
        numbers.forEach(element => {
            if (element in this.state.arabic_numbers)
                element = this.state.arabic_numbers[element];
            phone_str += element;
        });
        code = phone_str;
        if (this.isValidCode(code)) {
            this.setState({ vCode: code });
        }
    }

    isValidCode = (code) => {
        var regex = /^\d*$/;
        if (code == undefined || code == null)
            code = "";
        return code.length <= 4 && code.match(regex);
    }

    sendCode = (code, phone) => {
        phone = this.state.values.phone;
        let params = {
            fb_id: this.state.fb_id,
            code: code,
            phone: phone
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/verify', params).then(response => {
            if (response.data.code == 422) {
                this.setState({ validCode: false })
            } else {
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0, vCode: "" });
            }
        }).catch(error => {
            this.setState({ validCode: false })
        });
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length == 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    /**
     * Render Phone Input Components
     */
    renderPhoneInput = () => {
        var inputProps = {
            key: "phone",
            element: {
                ar_inputLabel: "رقم الهاتف",
                en_inputLabel: "phone",
                has_error: null,
                inputDisabled: false,
                inputName: "phone",
                inputRequired: true,
                inputToolTip: "Please enter phone",
                randomDisabledID: null,
                randomRequiredID: null,
                type: "phone"
            },
            changed: this.inputChangedHandler,
            value: this.state.application.formData['phone'].value,

            phoneVerify: this.phoneVerifyHandler,
            codeSent: this.state.codeSent,
            validPhone: this.state.validPhone,
            countDown: this.state.countDown,
            codeResent: this.state.codeResent,
            codeVerify: this.codeVerifyHandler,
            codeChanged: this.codeChangedHandler,
            vCode: this.state.vCode,
            validCode: this.state.validCode,
            phoneVerified: this.state.phoneVerified,
        }
        return (<Input {...inputProps} />);
    }

    /**
     * END Phone Verification Process
    */


    render() {
        const phoneInput = this.renderPhoneInput();
        let shouldVerify = false;
        //PHONE VERIFY
        if (this.state.values.profession == 0) {
            if (this.state.userAge < 21 || this.state.values.professionSubCategory == 8 ||
                this.state.values.professionSubCategory == 9 || this.state.values.professionSubCategory == 10 ||
                this.state.values.professionSubCategory == 11 || this.state.values.professionSubCategory == 12 ||
                this.state.values.professionSubCategory == 5 || this.state.values.salaryRange == 0 ||
                this.state.values.salaryRange == 1) {
                shouldVerify = false;

            } else {
                shouldVerify = true;
            }
        } else if (this.state.values.profession == 1) {
            if (this.state.userAge < 21 ||
                this.state.values.professionSubCategory == 3 || this.state.values.professionSubCategory == 14 ||
                this.state.values.professionSubCategory == 15 ||
                this.state.values.companyEstablishedFrom == 0 || this.state.values.companyEstablishedFrom == 1) {
                shouldVerify = false;

            } else {
                shouldVerify = true;
            }
        } else if (this.state.values.profession == 2) {
            if (this.state.userAge < 30 || this.state.userAge > 59) {
                shouldVerify = false;

            } else {
                shouldVerify = true;
            }
        } else if (this.state.values.profession == 3) {
            if (this.state.userAge >= 40 && this.state.userAge < 59) {
                if (this.state.values.professionSubCategory == 0 || this.state.values.professionSubCategory == 1 ||
                    this.state.values.professionSubCategory == 2) {
                    if (this.state.values.drStillWorking == 0) {
                        shouldVerify = true;
                    } else {
                        shouldVerify = false;
                    }
                } else if (this.state.values.professionSubCategory == 3) {
                    if (this.state.values.drClinic == 0) {
                        shouldVerify = true;
                    } else {
                        shouldVerify = false;
                    }
                }
            } else {
                shouldVerify = false;
            }
        } else if (this.state.values.profession == 4) {
            if (!this.state.userAge || this.state.userAge < 21 ||
                this.state.values.professionSubCategory == 0 || this.state.values.professionSubCategory == 3 ||
                this.state.values.pharmacyEstablishedFrom == 0 || this.state.values.pharmacyEstablishedFrom == 1) {
                shouldVerify = false;
            } else {
                shouldVerify = true;
            }
        } else if (this.state.values.profession || this.state.values.profession == 5 || this.state.values.profession == 6 ||
            this.state.values.profession == 7 || this.state.values.profession == 8 ||
            this.state.values.profession == 9) {
            shouldVerify = false;
        }

        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", "هيتم النظر فيها والتواصل معاك في اقرب وقت"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'التقديم على قرض  "' + 'بنك امارات دبي الوطني' + '" '} company_logo={this.state.company_logo} />
                            </header>
                            {this.state.error && <p className={classes.Error}>حدث خطأ حاول مره اخري</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>


                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.loanType} name='loanType'>
                                                <option value='' disabled defaultValue>نوع القرض</option>
                                                {this.state.loanTypes.map((loanType) => {
                                                    return <option key={loanType.id} value={loanType.id}>{loanType.ar_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                            <p style={{ color: '#727272', fontSize: '12px' }}>في حالة وجود سجل تجاري برجاء كتابة الأسم كما في السجل</p>
                                        </div>
                                    </div>
                                    {!this.state.nameValid && <p style={{ color: 'Red' }}>  يجب ان يحتوي الاسم على حروف عربية و مسافات فقط</p>}

                                    <label>تاريخ الميلاد</label>
                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input style={{ textAlign: "right" }} placeholder="تاريخ الميلاد" type="date"
                                                onChange={this.onInputChanged} value={this.state.values.birthday} required name="birthday" />
                                        </div>
                                    </div>
                                    {!this.state.dateValid && <p style={{ color: 'Red' }}>تاريخ الميلاد يجب ان لايقل عن 18 عام</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control " + (this.state.nationalIdValid ? "is-valid" : "")} onChange={this.onInputChanged} maxLength='14' value={this.state.values.national_id} type='number' placeholder="الرقم القومي" required name="national_id" />
                                        </div>
                                    </div>
                                    {this.state.values.national_id && !this.state.nationalIdValid && <p style={{ color: 'Red' }}>الرقم القومي غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.email} placeholder="البريد الالكتروني" name="email" />
                                        </div>
                                    </div>
                                    {!this.state.emailValid && <p style={{ color: 'Red' }}>البريد الالكتروني غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.governorate} name='governorate'>
                                                <option value='' disabled defaultValue>المحافظة</option>
                                                {this.state.governorates.map((governorate) => {
                                                    return <option key={governorate.ar_name} value={governorate.id}>{governorate.ar_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.profession} name='profession'>
                                                <option value='' disabled defaultValue>المهنة</option>
                                                {this.state.professions.map((profession) => {
                                                    return <option key={profession.id} value={profession.id}>{profession.ar_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        this.state.values.profession && this.state.values.profession == 0 ?
                                            <div>

                                                < div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.professionSubCategory} name='professionSubCategory'>
                                                            <option value='' disabled defaultValue>اختر</option>
                                                            {this.state.professions[this.state.values.profession].sub_category.map((subProfession) => {
                                                                return <option key={subProfession.id} value={subProfession.id}>{subProfession.ar_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="text" placeholder="اسم الوظيفة" onChange={this.onInputChanged} value={this.state.values.officerWorkName} required name="officerWorkName" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="text" placeholder="اسم جهة العمل" onChange={this.onInputChanged} value={this.state.values.officerWorkLocation} required name="officerWorkLocation" />
                                                    </div>
                                                </div>

                                                {
                                                    this.state.values.professionSubCategory == 13 ?
                                                        <div className="row">
                                                            <div className="col-12 py-2">
                                                                <input type="text" placeholder="الوصف" onChange={this.onInputChanged} value={this.state.values.professionOtherSubCategory} required name="professionOtherSubCategory" />
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.salaryRange} name='salaryRange'>
                                                            <option value='' disabled defaultValue>متوسط الدخل :</option>
                                                            {this.state.salaryRanges.map((salaryRange) => {
                                                                return <option key={salaryRange.ar_name} value={salaryRange.id}>{salaryRange.ar_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.values.profession == 1 ?
                                            <div>
                                                < div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.professionSubCategory} name='professionSubCategory'>
                                                            <option value='' disabled defaultValue>اختر</option>
                                                            {this.state.professions[this.state.values.profession].sub_category.map((subProfession) => {
                                                                return <option key={subProfession.id} value={subProfession.id}>{subProfession.ar_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.nameIncluded} name='nameIncluded'>
                                                            <option value='' disabled defaultValue>هل اسمك مسجل في السجل التجاري الخاص بالشركة؟</option>
                                                            <option value="0" >نعم</option>
                                                            <option value="1" >لا</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="text" placeholder="اسم الشركة" onChange={this.onInputChanged} value={this.state.values.companyName} required name="companyName" />
                                                    </div>
                                                </div>

                                                {
                                                    this.state.values.professionSubCategory == 17 ?
                                                        <div className="row">
                                                            <div className="col-12 py-2">
                                                                <input type="text" placeholder="الوصف" onChange={this.onInputChanged} value={this.state.values.professionOtherSubCategory} required name="professionOtherSubCategory" />
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.companyGovernorate} name='companyGovernorate'>
                                                            <option value='' disabled defaultValue>الشركة في أي محافظة</option>
                                                            {this.state.governorates.map((governorate) => {
                                                                return <option key={governorate.ar_name} value={governorate.id}>{governorate.ar_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.companyEstablishedFrom} name='companyEstablishedFrom'>
                                                            <option value='' disabled defaultValue>شركتك شركة قائمة منذ:</option>
                                                            {this.state.companyAges.map((companyAge) => {
                                                                return <option key={companyAge.ar_name} value={companyAge.id}>{companyAge.ar_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.values.profession == 2 ?
                                            < div className="row">
                                                <div className="col-12 py-2">
                                                    <select onChange={this.onInputChanged} required value={this.state.values.professionSubCategory} name='professionSubCategory'>
                                                        <option value='' disabled defaultValue>اختر</option>
                                                        {this.state.professions[this.state.values.profession].sub_category.map((subProfession) => {
                                                            return <option key={subProfession.id} value={subProfession.id}>{subProfession.ar_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.values.profession == 3 ?
                                            < div className="row">
                                                <div className="col-12 py-2">
                                                    <select onChange={this.onInputChanged} required value={this.state.values.professionSubCategory} name='professionSubCategory'>
                                                        <option value='' disabled defaultValue>اختر</option>
                                                        {this.state.professions[this.state.values.profession].sub_category.map((subProfession) => {
                                                            return <option key={subProfession.id} value={subProfession.id}>{subProfession.ar_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }

                                    {
                                        this.state.values.profession == 3 && this.state.values.professionSubCategory >= 0 && this.state.values.professionSubCategory <= 2 ?
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <select onChange={this.onInputChanged} required value={this.state.values.drStillWorking} name='drStillWorking'>
                                                            <option value='' disabled defaultValue>هل انت ممارس للمهنة؟</option>
                                                            <option value="0" >نعم</option>
                                                            <option value="1" >لا</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.values.profession == 3 && this.state.values.professionSubCategory == 3 ?
                                            <div className="row">
                                                <div className="col-12 py-2">
                                                    <select onChange={this.onInputChanged} required value={this.state.values.drClinic} name='drClinic'>
                                                        <option value='' disabled defaultValue>هل تتمتلك مركز علاج طبيعي؟</option>
                                                        <option value="0" >نعم</option>
                                                        <option value="1">لا</option>
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.values.profession == 4 ?
                                        
                                        < div className="row">
                                            <div className="col-12 py-2">
                                                <select onChange={this.onInputChanged} required value={this.state.values.professionSubCategory} name='professionSubCategory'>
                                                    <option value='' disabled defaultValue>اختر</option>
                                                    {this.state.professions[this.state.values.profession].sub_category.map((subProfession) => {
                                                        return <option key={subProfession.id} value={subProfession.id}>{subProfession.ar_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    {
                                        this.state.values.profession == 4 && this.state.values.professionSubCategory <=2 ?

                                        <div className="row">
                                            <div className="col-12 py-2">
                                                <select onChange={this.onInputChanged} required value={this.state.values.pharmacyEstablishedFrom} name='pharmacyEstablishedFrom'>
                                                    <option value='' disabled defaultValue> صيدليتك قائمة منذ:</option>
                                                    {this.state.pharmacyAges.map((pharmacyAge) => {
                                                        return <option key={pharmacyAge.id} value={pharmacyAge.id}>{pharmacyAge.ar_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                            :
                                            null
                                    }
                                            
                                    {
                                        this.state.values.profession && this.state.values.profession == 9 ?
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="text" placeholder="الوصف" onChange={this.onInputChanged} value={this.state.values.professionOtherSubCategory} required name="professionOtherSubCategory" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="text" placeholder="جهة العمل" onChange={this.onInputChanged} value={this.state.values.professionOtherLocation} required name="professionOtherLocation" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input type="number" placeholder="متوسط الراتب" onChange={this.onInputChanged} value={this.state.values.professionOtherSalary} required name="professionOtherSalary" />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        shouldVerify ?
                                            <div>
                                                {phoneInput}
                                                {!this.state.phoneValid && <p style={{ color: 'Red' }}>رقم التليفون غير صحيح</p>}
                                            </div>
                                            :
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 py-2">
                                                        <input className={"form-control " + (this.state.phoneVerified ? "is-valid" : "")} onChange={this.onInputChanged} maxLength='14' value={this.state.values.normalPhone} type='tel' placeholder="رقم التليفون" name="normalPhone" />
                                                    </div>
                                                </div>
                                                {!this.state.phoneValid && <p style={{ color: 'Red' }}>رقم التليفون غير صحيح</p>}
                                            </div>
                                    }

                                </div>
                                <footer>
                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />تأكيد</button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default LoanRequest;