import React from 'react';
import classes from './Select.module.css';

const select = (props) => {
    return (
        <select form="application" {...props.inputProps}  >
            <option value="" disabled defaultValue>{props.inputProps.placeholder}</option>
            {listOptions(props.element)}
        </select>
    );
}

const listOptions = (element) => {
    return element.inputChoices.map((element, index) => {
        return (
            <option className={classes.option} key={element} value={element}>
                {element}
            </option>
        )
    })
}

export default select;