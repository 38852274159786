import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './Contacts.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';


class ApplicationSubmission extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        /****** Start Phone Element  ********/
        application: {
            formData: {
                'phone': {
                    ar_inputLabel: "رقم الهاتف",
                    en_inputLabel: "phone",
                    has_error: null,
                    inputDisabled: false,
                    inputName: "phone",
                    inputRequired: true,
                    inputToolTip: "Please enter phone",
                    randomDisabledID: null,
                    randomRequiredID: null,
                    value: '',
                    type: "phone"
                }
            }
        },

        codeSent: false,
        codeResent: false,
        validPhone: true,
        countDown: 0,
        vCode: "",
        phone: "",
        validCode: true,
        submitError: false,
        triesForPhone: 0,
        triesForAccount: 0,
        requests: [],
        /******* End Phone Element *******/

        fb_id: null,
        platform: 'facebook',
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        cities: [],
        phoneVerified: true,
        phoneValid: true,
        formSubmitted: false,
        hide_other_city: 'd-none',
        type: null,
        error_code: 200,
        error_message: "حدث خطأ حاول مره اخري",
        error_messages: {
            400: "حدث خطأ حاول مره اخري",
            404: "الرابط المطلوب غير موجود",
            422: "تخطيت الحد المسموح يرجى إعادة التجربة بعد ساعة",
            502: "حدث خطأ في ارسال كود التفعيل",
        },

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        values: {
            form_id: 1,
            type_id: 1,
            type_en: 'Application',
            type_ar: 'طلب تمويل',
            name: '',
            phone: '',
            governorate_id: '',
            city_id: '',
            other_city: '',
            project: '',
            fund: '',
            details: ''
        }
    }

    componentDidMount() {
        document.title = 'Application Submission';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const platform = queryParams.get('platform')
        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');

        this.setState({ fb_id: fb_id, platform: platform, bot: bot, type: type });
        axios.get(process.env.REACT_APP_API_URL + '/governorates/get').then((response) => {
            this.setState({ governorates: response.data.data })
        }).catch(error => { });
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers })
        }).catch(error => { });
    }


    onInputChanged = (event) => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
    }



    formSubmitHandler = (event) => {
        event.preventDefault();
        if(this.state.fb_id.charAt(0) == " "){
            this.state.phoneVerified  = true;
        }
        if (this.state.phoneVerified) {
            this.setState({ loading: true });
            this.setState({ phoneValid: true });
            if (!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 || !/^\d+$/.test(this.state.values.phone)) {
                this.setState({ phoneValid: false });
                this.setState({ loading: false });
                return;
            }
            // TODO: Remove Whatsapp Demo for Tamweely
            let TAMWEELY_WHATSAPP = '';
            if(this.state.fb_id.charAt(0) == " "){
                TAMWEELY_WHATSAPP = '/demo/whatsapp';
            }
            axios.post(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/user-requests/contact-request', {
            // axios.post(process.env.REACT_APP_API_URL + '/user-requests/contact-request', {
                fb_id: this.state.fb_id,
                type: this.state.type,
                bot_name: this.state.bot,
                ...this.state.values
            }).then((res) => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);

            }).catch(error => {
                this.setState({ loading: false, error: true });
            })
        }
        else {
            this.setState({ validPhone: false });
        }
    }

    inputChangedHandler = (element, event) => {
        let application = { ...this.state.application };
        let value = event.target.value;
        if (element.type === "phone") {
            let numbers = value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
                console.log(element);
            });
            application.formData.phone.value = phone_str

            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
        }
        // application.formData[element.inputName] = value;
        this.setState({ application: application });
    }

    isValidPhone(phone) {
        var regex = /^\d+$/;
        return phone && phone.startsWith("01") && phone.length <= 14 && phone.length >= 11 && phone.match(regex);
    }

    /**
     * END Phone Verification Process
     */
    render() {
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen platform={this.state.platform} countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["شكراً ليك", "هيتم التواصل معك في اقرب وقت"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج طلب تواصل "' + this.state.company_name + '" '} company_logo={this.state.company_logo} />
                            </header>
                            {this.state.error && <p className={classes.Error}>{this.state.error_message}</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control "} onChange={this.onInputChanged} maxLength='14' value={this.state.values.phone} type='tel' placeholder="رقم التليفون" required name="phone" />
                                        </div>
                                    </div>
                                    {!this.state.phoneValid && <p style={{ color: 'Red' }}>رقم التليفون غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.governorate_id} name='governorate_id'>
                                                <option value="" disabled >المحافظة</option>
                                                {this.state.governorates.map((governorate) => {
                                                    return <option key={governorate.name_ar} value={governorate.id}>{governorate.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <footer>


                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />
                        تأكيد
                    </button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default ApplicationSubmission;