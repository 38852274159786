import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './ApplicationSubmission.module.css';
import Input from '../../../components/PhoneInput/Input';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';

import countDown from '../../Application/Utils/CountDown/CountDown';


class ApplicationSubmission extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        /****** Start Phone Element  ********/
        application: {
            formData: {
                'phone': {
                    ar_inputLabel: "رقم الهاتف",
                    en_inputLabel: "phone",
                    has_error: null,
                    inputDisabled: false,
                    inputName: "phone",
                    inputRequired: true,
                    inputToolTip: "Please enter phone",
                    randomDisabledID: null,
                    randomRequiredID: null,
                    value: '',
                    type: "phone"
                }
            }
        },
        
        codeSent: false,
        codeResent: false,
        validPhone: true,
        countDown: 0,
        vCode: "",
        phone: "",
        validCode: true,
        submitError: false,
        triesForPhone: 0,
        triesForAccount: 0,
        requests: [],
        /******* End Phone Element *******/

        fb_id: null,
        platform: 'facebook',
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        cities: [],
        phoneVerified:false,
        phoneValid:true,
        formSubmitted: false,
        hide_other_city: 'd-none',
        type: null,
        error_code: 200,
        error_message: "حدث خطأ حاول مره اخري",
        error_messages: {
            400: "حدث خطأ حاول مره اخري",
            404: "الرابط المطلوب غير موجود",
            422: "تخطيت الحد المسموح يرجى إعادة التجربة بعد ساعة",
            502: "حدث خطأ في ارسال كود التفعيل",
        },

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        values: {
            form_id: 1,
            type_id: 1,
            type_en: 'Application',
            type_ar: 'طلب تمويل',
            name: '',
            phone: '',
            governorate_id: '',
            city_id: '',
            other_city: '',
            project: '',
            fund: '',
            details: ''
        }
    }

    componentDidMount() {
        document.title = 'Application Submission';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const user_id = queryParams.get('user_id');
        const signature = queryParams.get('signature');
        const platform = queryParams.get('platform');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        const fund_type = queryParams.get('fund_type');
        const fund_types = {
            //"تمويل التروسيكل": "تمويل التروسيكل",
            "تمويل صغير جدا": "51000-200000",
            "تمويل متناهي الصغر": "5000-50000",
            "تمويل النقل الخفيف": "تمويل النقل الخفيف",
            "تمويل المعدات والآلات": "تمويل المعدات والآلات",
        }
        if(fund_type){
            // console.log(fund_type, fund_types[fund_type]);
            this.setState({
                values: {
                    ...this.state.values,
                    fund: fund_types[fund_type]
                }
            });
        }

        this.setState({ fb_id: fb_id, platform: platform, bot: bot, type: type });
        axios.get(process.env.REACT_APP_API_URL + '/governorates/get').then((response) => {
            this.setState({ governorates: response.data.data })
        }).catch(error => { });
        // axios.get(process.env.REACT_APP_API_URL + '/settings/get?fb_id=' + fb_id).then((response) => {
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers})
        }).catch(error => { });
    }


    onInputChanged = (event) => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
        if(event.target.name === 'governorate_id'){
            axios.get(process.env.REACT_APP_API_URL + '/cities/get/' + event.target.value).then((response) => {
                response.data.data.push({
                    governorate_id: 0,
                    id: 0,
                    name_ar: "أخرى ...",
                    name_en: "Others"
                });
                this.setState({ cities: response.data.data })
            }).catch(error => { });
        }
        if(event.target.name === 'phone'){
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
        }
        if(event.target.name === 'city_id'){
            if(event.target.value === '0') 
                this.setState({values: {...this.state.values, city_id: undefined}, hide_other_city: ''});
            else
                this.setState({hide_other_city: 'd-none'});
        }
    }

    

    formSubmitHandler = (event) => {
        event.preventDefault();
        // TODO: Remove Whatsapp Demo for Tamweely
        if(this.state.fb_id.charAt(0) == " "){
            this.state.phoneVerified  = true;
        }
        if (this.state.phoneVerified) {
            this.setState({ loading: true });
            this.setState({phoneValid:true});
            if(!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 ||  !/^\d+$/.test(this.state.values.phone)){            
                this.setState({phoneValid:false});
                return;
            }
            // TODO: Remove Whatsapp Demo for Tamweely
            let TAMWEELY_WHATSAPP = '';
            if(this.state.fb_id.charAt(0) == " "){
                TAMWEELY_WHATSAPP = '/demo/whatsapp';
            }
            axios.post(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/applications/create', {
                fb_id: this.state.fb_id,
                type: this.state.type,
                bot_name: this.state.bot,
                ...this.state.values
            }).then((res) => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);
    
            }).catch(error => {
                this.setState({ loading: false, error: true });
            })
        }
        else {
            this.setState({ validPhone: false });
        }
    }

    /**
     * Start Phone Verification Process
     */
    phoneVerifyHandler = (element) => {
        // let application = { ...this.state.application };
        // let phone = application.formData[element.inputName];
        let phone = this.state.values.phone;
        
        if (this.state.countDown === 0) {
            if (this.isValidPhone(phone)) {
                this.sendPhoneNumber(phone);
            }
            else {
                this.setState({ validPhone: false });
            }
        }
    }
    
    inputChangedHandler = (element, event) => {
        let application = { ...this.state.application };
        let value = event.target.value;
        if (element.type === "phone") {
            let numbers = value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
                console.log(element);
            });
            application.formData.phone.value = phone_str

            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
            // this.setState({
            //     values: {
            //         ...this.state.values,
            //         phone: value
            //     }
            // })
            
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
            // this.setState({ phoneVerified: false, codeSent: false })
        }
        // application.formData[element.inputName] = value;
        this.setState({ application: application });
    }

    isValidPhone(phone) {
        var regex = /^\d+$/;
        return phone && phone.startsWith("01") && phone.length <= 14 && phone.length >= 11 && phone.match(regex);
    }

    sendPhoneNumber = (phone) => {
        const NOW = new Date();
        let requests = [...this.state.requests];

        let lastRequestsForAccount = this.getLastRequestsOfAccount(requests);

        if (lastRequestsForAccount.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequestsForAccount)
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        else {
            let lastRequestsForPhone = lastRequestsForAccount.filter((request) => {
                return phone === request.phone
            });

            if (lastRequestsForPhone.length) {
                let lastRequestTime = this.getLastRequestTime(lastRequestsForPhone);
                const differnce = Math.floor((NOW - lastRequestTime) / 1000);
                switch (lastRequestsForPhone.length) {
                    case 1:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[0], phone, 1);
                        break;
                    case 2:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[1], phone, 2);
                        break;
                    case 3:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[2], phone, 3);
                        break;
                    case 4:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[3], phone, 4);
                        break;
                    case 5:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                    default:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                }
            }
            else {
                this.launchTimer(60);
                this.sendPhoneSMS(phone);
            }

        }
    }

    

    getLastRequestsOfAccount = (requests) => {
        const NOW = new Date();
        return requests.filter((request) => {
            return ((NOW.getTime() - (new Date(request.date).getTime() + (countDown.ONE_HOUR * 2))) < countDown.ONE_HOUR);
        });
    }

    launchTimer = (countDown) => {
        this.setState({ countDown: countDown });
        let countDownInterval = setInterval(
            () => {
                if (this.state.countDown <= 1) {
                    clearInterval(countDownInterval);
                    this.setState({ codeResent: false, countDown: 0 });
                }
                else {
                    this.setState((prevState) => ({
                        countDown: prevState.countDown - 1
                    }));
                }
            },
            1000);
    }

    sendPhoneSMS = (phone) => {
        let params = {
            fb_id: this.state.fb_id,
            phone: phone,
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/send', params).then(response => {
            console.log(response);
            if (response.data.code === 200) {
                this.setState({
                    error: false,
                    codeSent: true,
                    codeResent: true,
                    validPhone: true, phone: phone,
                    // requests: this.filterRequestsForLastHour(response.data.verification_requests)
                });
            }
            else if (response.data.code === 401) {
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0 });
            }
            else {
                this.setState({
                    error: true,
                    error_code: response.data.code,
                    error_message: (response.data.code in this.state.error_messages) ? this.state.error_messages[response.data.code] : this.state.error_message,
                    validPhone: false
                });
            }

        }).catch(error => {
            this.setState({ validPhone: false });
        })
    }

    filterRequestsForLastHour = (requests) => {
        const NOW = new Date();
        let lastRequests = this.getLastRequestsOfAccount(requests);
        this.setState({ triesForAccount: lastRequests.length });
        if (lastRequests.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequests);
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        return lastRequests;
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length === 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    codeChangedHandler = (event) => {
        let code = event.target.value;
        let numbers = code.split("");
        let phone_str = '';
        numbers.forEach(element => {
            if (element in this.state.arabic_numbers)
                element = this.state.arabic_numbers[element];
            phone_str += element;
        });
        code = phone_str;
        if (this.isValidCode(code)) {
            this.setState({ vCode: code });
        }
    }

    isValidCode = (code) => {
        var regex = /^\d*$/;
        if (code === undefined || code === null)
            code = "";
        return code.length <= 4 && code.match(regex);
    }

    sendCode = (code, phone) => {
        phone = this.state.values.phone;
        console.log(phone);
        let params = {
            fb_id: this.state.fb_id,
            code: code,
            phone: phone
        };
        axios.post(process.env.REACT_APP_API_URL + '/sms/verify', params).then(response => {
            console.log(response.data.code);
            if(response.data.code === 422){
                this.setState({ validCode: false })    
            }else{
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0, vCode: "" });
            }
        }).catch(error => {
            this.setState({ validCode: false })
        });
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length === 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    /**
     * Render Phone Input Components
     */
    renderPhoneInput = () => {
        var inputProps = {
            key: "phone",
            element: {
                ar_inputLabel: "رقم الهاتف",
                en_inputLabel: "phone",
                has_error: null,
                inputDisabled: false,
                inputName: "phone",
                inputRequired: true,
                inputToolTip: "Please enter phone",
                randomDisabledID: null,
                randomRequiredID: null,
                type: "phone"
            },
            changed: this.inputChangedHandler,
            value: this.state.application.formData['phone'].value,
            
            phoneVerify: this.phoneVerifyHandler,
            codeSent: this.state.codeSent,
            validPhone: this.state.validPhone,
            countDown: this.state.countDown,
            codeResent: this.state.codeResent,
            codeVerify: this.codeVerifyHandler,
            codeChanged: this.codeChangedHandler,
            vCode: this.state.vCode,
            validCode: this.state.validCode,
            phoneVerified: this.state.phoneVerified,
        }
        return (<Input {...inputProps} />);
    }

    /**
     * END Phone Verification Process
     */
    render() {
        const phoneInput = this.renderPhoneInput();
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen platform={this.state.platform} countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", " و هنتواصل معاك في خلال ٤٨ ساعة"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج تسجيل بيانات "' + this.state.company_name + '" '}  company_logo={this.state.company_logo}/>
                            </header>
                            {this.state.error && <p className={classes.Error}>{this.state.error_message}</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    {phoneInput}

                                    {!this.state.phoneValid && <p style={{color:'Red'}}>رقم التليفون غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.governorate_id} name='governorate_id'>
                                                <option value="" disabled >المحافظة</option>
                                                {this.state.governorates.map((governorate) => {
                                                    return <option key={governorate.name_ar} value={governorate.id}>{governorate.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.city_id} name='city_id'>
                                                <option value="" disabled >المركز</option>
                                                {this.state.cities.map((city) => {
                                                    return <option key={city.name_ar} value={city.id}>{city.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"row " + this.state.hide_other_city }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} maxLength='14' value={this.state.values.other_city} type='text' placeholder="اسم المركز" name="other_city" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize value={this.state.values.project} onChange={this.onInputChanged} placeholder="مجال المشروع" required name="project" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select value={this.state.values.fund} onChange={this.onInputChanged} required name='fund'>
                                                <option value="" disabled >قيمة التمويل المطلوب</option>
                                                {/* <option value="تمويل تروسيكل">تمويل تروسيكل</option> */}
                                                <option value="5000-50000">من 5,000 إلى 50,000</option>
                                                <option value="51000-200000">من 51,000 إلى 200,000</option>
                                                <option value="تمويل النقل الخفيف">تمويل النقل الخفيف</option>
                                                <option value="تمويل المعدات والآلات">تمويل المعدات والآلات</option>
                                                <option value="التمويل الأخضر">التمويل الأخضر</option>
                                                <option value="تمويل مشروعات ذوي الهمم">تمويل مشروعات ذوي الهمم</option>
                                                <option value="تمويل الشباب">تمويل الشباب</option>
                                                <option value="ماكينات الدفع الإلكتروني (POS)">ماكينات الدفع الإلكتروني (POS)</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize  value={this.state.values.details} onChange={this.onInputChanged} placeholder="اضافة تفاصيل" name="details" />
                                        </div>
                                    </div>

                                </div>
                                <footer>


                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />
                        تأكيد
                    </button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default ApplicationSubmission;
