import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './UploadDocuments.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';

import countDown from '../../Application/Utils/CountDown/CountDown';

import _ from 'lodash'

class UploadDocuments extends Component {

    state = {
        // Fill from the settings
        company_name: 'امان',
        company_logo: '',

        fb_id: null,
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        formSubmitted: false,
        type: null,
        form_id: null,

        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        idFileValid: true,
        idLicenseFileValid: true,
        idFile: '',
        idLicenseFile: '',


        values: {
            name: 'NON',
            date: Date.now(),
        }
    }

    componentDidMount() {
        document.title = 'installment documents upload';

        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        const form_id = queryParams.get('form_id');

        this.setState({ fb_id: fb_id, bot: bot, type: type, form_id: form_id });
    }

    onInputChanged = (event) => {
        if (event.target.name === 'idFile' ||
            event.target.name === 'idLicenseFile')
            this.setState({
                [event.target.name]: event.target.files[0]
            })

    }

    formSubmitHandler = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append(
            "idFile",
            this.state.idFile
        );
        formData.append(
            "idLicenseFile",
            this.state.idLicenseFile
        );
        formData.append(
            "fb_id",
            this.state.fb_id
        );
        formData.append(
            "type",
            this.state.type
        );
        formData.append(
            "bot_name",
            this.state.bot
        );

        formData.append(
            "form_id",
            this.state.form_id
        );

        _.each(this.state.values, (element, key) => {
            formData.append(key, element)
        });

        this.setState({ loading: true });
        axios.post(process.env.REACT_APP_API_URL + '/applications/create', formData).then((res) => {
            console.log(res);
            this.setState({ loading: false, formSubmitted: true });
            let skipInterval = setInterval(
                () => {
                    if (this.state.skipCountdown <= 1) {
                        clearInterval(skipInterval);
                        window.closeWebView();
                    }
                    else {
                        this.setState((prevState) => ({
                            skipCountdown: prevState.skipCountdown - 1
                        }));
                    }
                },
                1000);

        }).catch(error => {
            this.setState({ loading: false, error: true });
        })
    }

    render() {
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", "هيتم النظر فيها والتواصل معاك في اقرب وقت"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'رفع مستندات التقسيط'} company_logo={this.state.company_logo} />
                            </header>
                            {this.state.error && <p className={classes.Error}>حدث خطأ حاول مره اخري</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <label >كشف حساب بنكي :</label>
                                            <br />
                                            <label for="idFile"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    display: "inline - block",
                                                    padding: "6px 12px",
                                                    cursor: "pointer"
                                                }}>اختار ملف</label>
                                            <label>{this.state.idFile.name}</label>
                                            <p style={{ color: '#727272', fontSize: '12px' }}>يتم فقط رفع ملفات بالصيغ التالية  :  (pdf, docx, doc, png, jpg, jpeg)</p>
                                        </div>
                                        {!this.state.idFileValid && <p style={{ color: 'Red' }}>يجب ارفاق ملف من الصيغة :  (pdf, docx, doc, png, jpg, jpeg)</p>}
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <label>ايصال مرافق :</label>
                                            <br />
                                            <label for="idLicenseFile"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    display: "inline - block",
                                                    padding: "6px 12px",
                                                    cursor: "pointer"
                                                }}>اختار ملف</label>
                                            <label>{this.state.idLicenseFile.name}</label>
                                            <p style={{ color: '#727272', fontSize: '12px' }}>يتم فقط رفع ملفات بالصيغ التالية  :  (pdf, docx, doc, png, jpg, jpeg)</p>

                                        </div>
                                    </div>
                                    {!this.state.idLicenseFileValid && <p style={{ color: 'Red' }}>يجب ارفاق ملف من الصيغة :  (pdf, docx, doc, png, jpg, jpeg)</p>}

                                </div>
                                <input id="idFile" style={{ visibility: "hidden" }} type="file" onChange={this.onInputChanged}
                                    required name="idFile" />
                                <input id="idLicenseFile" style={{ visibility: "hidden" }} type="file" onChange={this.onInputChanged}
                                    required name="idLicenseFile" />

                                <footer>
                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />تأكيد</button>
                                </footer>

                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default UploadDocuments;