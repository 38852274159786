import actions from '../actions';
const initialState = {
    language: 'en',
    bot: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.language
            }
        case actions.CHANGE_BOT: {
            return {
                ...state,
                bot: action.bot
            }
        }
        default:
            return state
    }
}

export default reducer;