import React, { Component } from 'react';
import Complaint from './Complaint/Complaint';
import UploadDocuments from './UploadDocuments/UploadDocuments';

/*
import ApplicationSubmission from './ApplicationSubmission/ApplicationSubmission';
import Career from './Career/Career';
import Contacts from './Contacts/Contacts';
import Location from './Location/Location';
import Suggestion from './Suggestion/Suggestion';
*/


class Aman extends Component {

    render() {
        let form = null;
        const queryParams = new URLSearchParams(this.props.location.search);
        const type = queryParams.get('type');
        switch (type) {
            case 'uploadDocuments':
                form = <UploadDocuments />
                break;
            case 'career':
                //form = <Career />
                break;
            case 'complaint':
                form = <Complaint />
                break;
            case 'contacts':
                //form = <Contacts />
                break;
            case 'location':
                //form = <Location />
                break;
            case 'suggestion':
                //form = <Suggestion />
                break;
            default:
                break;
        }
        return (form);
    }

}


export default Aman;