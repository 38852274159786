import React, { Component } from 'react';
import Header from './Header/Header';
import axios from 'axios';
import classes from './Application.module.css';
import Input from '../../components/Input/Input';
import sendImage from '../../assets/send_white.png'
import Loader from '../../components/UI/Loader/Loader';
import FadeIn from '../../components/UI/FadeIn/FadeIn';
import SuccessScreen from '../../components/SuccessScreen/SuccessScreen';
import FailureScreen from '../../components/FailureScreen/FailureScreen';
import Switch from '../../components/UI/Switch/Switch';
import ErrorBoundry from '../../ErrorBoundary'
import { connect } from 'react-redux';
import actions from '../../store/application/actions';
import local from '../../localization/local';
import countDown from './Utils/CountDown/CountDown';

class Application extends Component {
    state = {
        application: {
            fb_id: "",
            form_id: "",
            card_image: "",
            reference_id: "",
            card: {},
            bank: {},
            provider: {},
            form: {
                elements: null,
            },
            formData: {}
        },
        error: false,
        loading: true,
        codeSent: false,
        codeResent: false,
        validPhone: true,
        countDown: 0,
        phoneVerified: false,
        vCode: "",
        phone: "",
        validCode: true,
        formSubmitted: false,
        skipCountdown: 5,
        submitError: false,
        triesForPhone: 0,
        triesForAccount: 0,
        requests: []
    }

    componentDidMount() {
        this.getFormData();
    }

    getFormData() {
        const params = this.props.match.params;
        document.title = params.bank;
        const queryParams = new URLSearchParams(this.props.location.search);
        const fbId = queryParams.get('fb_id');
        const bot = queryParams.get('bot');
        this.props.changeBot(bot);

        let reqParams = {
            bank: params.bank,
            watson_name: params.watson_name,
            bot: bot,
            fb_id: fbId
        }

        switch (bot) {
            case 'sally':
                const provider = queryParams.get('provider');
                reqParams = {
                    ...reqParams,
                    provider: provider
                }
                break;
            case 'sara':
                const collateral_types = queryParams.get('collateral_types');
                reqParams = {
                    ...reqParams,
                    collateral_types: collateral_types,
                }
                break;
            default:
                break;
        }

        axios.post('/bank/form', { ...reqParams }).then((response) => {
            const data = response.data.data.application;
            console.log(data);

            let formData = this.initFormData(data.form.elements);
            this.setState({
                application: {
                    fb_id: fbId,
                    bank: data._bank,
                    card: data.category,
                    provider: data.provider,
                    collateral_types: data.collateral_type,
                    loan_type: data.loan_type,
                    reference_id: data._id,
                    form_id: data.form_id,
                    card_image: data.unified_img,
                    form: { elements: data.form.elements },
                    formData: { ...formData },
                },
                error: false,
                loading: false,
                requests: this.filterRequestsForLastHour(response.data.data.verification_requests)
            })
        }).catch(error => {
            console.log(error);

            this.setState({ error: true, loading: false })
        });
    }

    initFormData = (elements) => {
        let formData = {};
        elements.forEach(element => {
            switch (element.type) {
                case 'checkbox':
                    formData[element.inputName] = false;
                    break;
                case 'location':
                    formData[element.inputName] = { governorate: "", city: "", region: "", long: '', lat: '' };
                    break;
                default:
                    formData[element.inputName] = "";
                    break;
            }
        });
        return formData;
    }



    //Phone Functions

    isValidPhone(phone) {
        var regex = /^\d+$/;
        return phone && phone.startsWith("01") && phone.length <= 14 && phone.length >= 11 && phone.match(regex);
    }

    filterRequestsForLastHour = (requests) => {
        const NOW = new Date();
        let lastRequests = this.getLastRequestsOfAccount(requests);
        this.setState({ triesForAccount: lastRequests.length });
        if (lastRequests.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequests);
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        return lastRequests;
    }

    getLastRequestsOfAccount = (requests) => {
        const NOW = new Date();
        return requests.filter((request) => {
            return ((NOW.getTime() - (new Date(request.date).getTime() + (countDown.ONE_HOUR * 2))) < countDown.ONE_HOUR);
        });
    }

    sendPhoneSMS = (phone) => {
        let params = {
            fb_id: this.state.application.fb_id,
            phone: phone,
        };
        axios.post('/sms/send', params).then(response => {
            if (response.data.code === 401) {
                this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0 });
            }
            else {
                this.setState({
                    codeSent: true,
                    codeResent: true,
                    validPhone: true, phone: phone,
                    requests: this.filterRequestsForLastHour(response.data.verification_requests)
                });
            }

        }).catch(error => {
            this.setState({ validPhone: false });
        })
    }

    launchTimer = (countDown) => {
        this.setState({ countDown: countDown });
        let countDownInterval = setInterval(
            () => {
                if (this.state.countDown <= 1) {
                    clearInterval(countDownInterval);
                    this.setState({ codeResent: false, countDown: 0 });
                }
                else {
                    this.setState((prevState) => ({
                        countDown: prevState.countDown - 1
                    }));
                }
            },
            1000);
    }

    getLastRequestTime = (requests) => {
        return new Date(Math.max.apply(null, requests.map(function (request) {
            return new Date(request.date);
        }))).getTime() + (countDown.ONE_HOUR * 2);
    }

    getFirstRequestTime = (requests) => {
        return new Date(Math.min.apply(null, requests.map(function (request) {
            return new Date(request.date);
        }))).getTime() + (countDown.ONE_HOUR * 2);
    }

    checkIfCanSendMessage = (differnce, time, phone, tries) => {
        if (differnce >= time) {
            this.launchTimer(countDown.COUNTDOWNS[tries]);
            this.sendPhoneSMS(phone);
        }
        else {
            this.setState({ codeSent: true });
            this.launchTimer(time - differnce);
        }
    }

    sendPhoneNumber = (phone) => {
        const NOW = new Date();
        let requests = [...this.state.requests];

        let lastRequestsForAccount = this.getLastRequestsOfAccount(requests);

        if (lastRequestsForAccount.length >= 10) {
            let firstRequestTime = this.getFirstRequestTime(lastRequestsForAccount)
            const differnce = Math.floor((firstRequestTime + countDown.ONE_HOUR - NOW) / 1000);
            this.launchTimer(differnce)
        }
        else {
            let lastRequestsForPhone = lastRequestsForAccount.filter((request) => {
                return phone === request.phone
            });

            if (lastRequestsForPhone.length) {
                let lastRequestTime = this.getLastRequestTime(lastRequestsForPhone);
                const differnce = Math.floor((NOW - lastRequestTime) / 1000);
                switch (lastRequestsForPhone.length) {
                    case 1:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[0], phone, 1);
                        break;
                    case 2:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[1], phone, 2);
                        break;
                    case 3:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[2], phone, 3);
                        break;
                    case 4:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[3], phone, 4);
                        break;
                    case 5:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                    default:
                        this.checkIfCanSendMessage(differnce, countDown.COUNTDOWNS[4], phone, 5);
                        break;
                }
            }
            else {
                this.launchTimer(60);
                this.sendPhoneSMS(phone);
            }

        }
    }

    phoneVerifyHandler = (element) => {
        let application = { ...this.state.application };
        let phone = application.formData[element.inputName];
        if (this.state.countDown === 0) {
            if (this.isValidPhone(phone)) {
                this.sendPhoneNumber(phone);
            }
            else {
                this.setState({ validPhone: false });
            }
        }
    }

    // Phone functions

    //Code functions

    isValidCode = (code) => {
        var regex = /^\d*$/;
        if (code === undefined || code === null)
            code = "";
        return code.length <= 4 && code.match(regex);
    }

    sendCode = (code, phone) => {
        let params = {
            fb_id: this.state.application.fb_id,
            code: code,
            phone: phone
        };
        axios.post('/sms/verify', params).then(response => {
            this.setState({ validCode: true, phoneVerified: true, validPhone: true, countDown: 0, vCode: "" });
        }).catch(error => {
            this.setState({ validCode: false })
        });
    }

    codeVerifyHandler = (element) => {
        const phone = this.state.application.formData[element.inputName];
        let code = this.state.vCode;
        if (code.length === 4) {
            this.sendCode(code, phone);
        }
        else {
            this.setState({ validCode: false });
        }
    }

    codeChangedHandler = (event) => {
        let code = event.target.value;
        if (this.isValidCode(code)) {
            this.setState({ vCode: event.target.value });
        }
    }

    // Code functions

    inputChangedHandler = (element, event) => {
        let application = { ...this.state.application };
        let value = event.target.value;
        if (element.type === "number") {
            value = parseInt(value);
            if (isNaN(value))
                value = null;
        }
        if (element.type === "checkbox") {
            value = event.target.checked
        }
        if (element.type === "phone") {
            this.setState({ phoneVerified: false, codeSent: false })
        }
        application.formData[element.inputName] = value;
        this.setState({ application: application });
    }

    listAllCountries = () => {
        axios.get('/locations').then((response) => {
            this.setState({ countries: response.data.data });
        }).catch(error => { });
    }

    locationDetectHandler = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude,
                });
            });
        }
    }

    formSubmitHandler = (event) => {
        event.preventDefault();
        if (this.state.phoneVerified) {
            this.setState({ loading: true });
            let params = {
                fb_id: this.state.application.fb_id,
                form_id: this.state.application.form_id,
                reference_id: this.state.application.reference_id,
                reference_type: this.props.bot,
                ...this.state.application.formData
            };

            if (this.state.application.bank.name_en === "ValU") {
                params = {
                    ...params,
                    flag: 'ValU'
                }
            }
            axios.post('/form/submit', params).then(response => {
                this.setState({ loading: false, formSubmitted: true });
                let skipInterval = setInterval(
                    () => {
                        if (this.state.skipCountdown <= 1) {
                            clearInterval(skipInterval);
                            window.closeWebView();
                        }
                        else {
                            this.setState((prevState) => ({
                                skipCountdown: prevState.skipCountdown - 1
                            }));
                        }
                    },
                    1000);

            }).catch(error => {
                this.setState({ loading: false, formSubmitted: false, submitError: true });
            })
        }
        else {
            this.setState({ validPhone: false });
        }
    }

    tryAgainHandler = () => {
        this.setState({ submitError: false });
    }

    closeWebView = () => {
        window.closeWebView();
    }

    getGovernments = () => {
        let governments = [];
        if (this.state.countries) {
            for (let i = 0; i < this.state.countries.length; i++) {
                governments.push(this.state.countries[i].name_en);
                governments.push(this.state.countries[i].name_ar);
            }
        }
        return governments;
    }

    getCities = (governmentName) => {
        let cities = [];
        let government = null;
        if (this.state.countries && governmentName) {
            for (let i = 0; i < this.state.countries.length; i++) {
                if (this.state.countries[i].name_en === governmentName || this.state.countries[i].name_ar === governmentName) {
                    government = this.state.countries[i];
                    break;
                }
            }
            if (government) {
                for (let i = 0; i < government.cities.length; i++) {
                    cities.push(government.cities[i].name_en);
                    cities.push(government.cities[i].name_ar);
                }
            }
        }

        return cities;
    }

    locationChangedHandler = (element, key, value) => {
        let application = { ...this.state.application };
        if (key === "region") {
            value = value.target.value;
        }
        application.formData[element.inputName] = {
            ...application.formData[element.inputName],
            [key]: value
        };
        this.setState({ application: application });
    }

    getForm = () => {
        return this.state.application.form.elements.map((element, index) => {
            let inputProps = {
                key: element.inputName,
                element: element,
                changed: this.inputChangedHandler,
                value: this.state.application.formData[element.inputName],
            }

            if (element.type === 'location') {
                if (!this.state.countries) { this.listAllCountries(); }

                inputProps.changed = this.locationChangedHandler;

                inputProps = {
                    ...inputProps,
                    locationDetect: this.locationDetectHandler,
                    governments: this.getGovernments(),
                    cities: this.getCities(this.state.application.formData[element.inputName]['governorate'])
                }
            }
            if (element.type === "phone") {
                inputProps = {
                    ...inputProps,
                    phoneVerify: this.phoneVerifyHandler,
                    codeSent: this.state.codeSent,
                    validPhone: this.state.validPhone,
                    countDown: this.state.countDown,
                    codeResent: this.state.codeResent,
                    codeVerify: this.codeVerifyHandler,
                    codeChanged: this.codeChangedHandler,
                    vCode: this.state.vCode,
                    validCode: this.state.validCode,
                    phoneVerified: this.state.phoneVerified,
                }
            }
            return (<Input {...inputProps} />);
        });
    }

    languageChangedHandler = () => {
        this.props.changeLanguage(this.props.language === 'en' ? 'ar' : 'en');
    }

    render() {
        let form = this.state.error ? <h1 className="text-center">Not found</h1> : <Loader />;
        if (this.state.application.form.elements && !this.state.loading) {
            const formElements = this.getForm();
            let headerProps = {
                image: this.state.application.card_image,
                bank: this.state.application.bank["name_" + this.props.language]
            }
            switch (this.props.bot) {
                case 'sally':
                    headerProps = {
                        ...headerProps,
                        provider: this.state.application.provider["name_" + this.props.language],
                        card: this.state.application.card["name_" + this.props.language]
                    }
                    break;
                case 'sara':
                    headerProps = {
                        ...headerProps,
                        loan_type: this.state.application.loan_type["name_" + this.props.language],
                        collateral_types: this.state.application.collateral_types[this.props.language]
                    }
                    break;
                default:
                    break;
            }
            form = (
                <FadeIn>
                    <header className="container">
                        <Header {...headerProps} />
                        <Switch onChange={this.languageChangedHandler} />
                    </header>

                    <form id="application"
                        onSubmit={this.formSubmitHandler}
                        method="post" action={`${axios.defaults.baseURL}/form/submit`}>
                        <div className={`container ${classes.form}`}>
                            {formElements}
                        </div>
                        <footer>
                            <button type="submit" className={classes.Submit} >
                                <img src={sendImage} alt="submit" />
                                {local.submit[this.props.language]}
                            </button>
                        </footer>
                    </form>
                </FadeIn>
            );
        }
        if (this.state.formSubmitted) {
            form = (
                <FadeIn>
                    <SuccessScreen countdown={this.state.skipCountdown} exit={this.closeWebView} />
                </FadeIn>)
        }
        if (this.state.submitError) {
            form = (
                <FadeIn>
                    <FailureScreen tryAgain={this.tryAgainHandler} exit={this.closeWebView} />
                </FadeIn>
            )
        }
        return <ErrorBoundry>{form}</ErrorBoundry>;
    }

}
const mapStateToProps = (state) => {
    return {
        language: state.language,
        bot: state.bot
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => { dispatch({ type: actions.CHANGE_LANGUAGE, language: language }) },
        changeBot: (bot) => { dispatch({ type: actions.CHANGE_BOT, bot: bot }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);