import React from 'react';
import classes from './SuccessScreen.module.css';
import Logo from '../../../assets/tamweely.jpeg';

const successScreen = (props) => {
    return (
        <div className={classes.Container}>
            <img src={Logo} alt="Tamweely" width="220" />
            <p>{props.messages[0]}</p>
            <p>{props.messages[1]}</p>
            {
                props.platform == 'whatsapp' ? 
                <div>
                    <p>هتلاقي رسالة التأكيد في الواتساب</p>
                </div>
                : 
                <div>
                    <button onClick={props.exit} >خروج</button>
                    <p className={classes.Countdown}>إغلاق بعد &nbsp; {props.countdown}</p>
                </div>
            }
            
        </div>
    );
}
export default successScreen;

