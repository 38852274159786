import React, { Component } from 'react';
import './App.css';
import Vendors from './containers/Vendors/Vendors';
import Application from './containers/Application/Application';
import Location from './containers/Location/Location'
import FacebookLogin from './containers/FacebookLogin/FacebookLogin'
import Tamweely from './containers/Tamweely/Tamweely'
import Aman from './containers/Aman/Aman'
import Emirates from './containers/Emirates/Emirates';
import Demo from './containers/Demo/Demo'
import { Route, Switch } from 'react-router-dom';

class App extends Component {

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path='/vendors/:id' component={Vendors} />
          <Route path='/application/:watson_name/:bank' component={Application} />
          <Route path='/login' component={FacebookLogin} />
          <Route path='/location' component={Location} />
          <Route path='/tamweely' component={Tamweely} />
          <Route path='/aman' component={Aman} />
          <Route path='/emirates' component={Emirates} />
          <Route path='/demo' component={Demo} />
          <Route path='/' render={() => <h1 className="text-center">Not found</h1>} />
        </Switch>
      </div>
    );
  }
}

export default App;
