import React from 'react';
import classes from './SuccessScreen.module.css';
import Logo from '../../../assets/aman.jpeg';

const successScreen = (props) => {
    return (
        <div className={classes.Container}>
            <img src={Logo} alt="Aman" width="220" />
            <p>{props.messages[0]}</p>
            <p>{props.messages[1]}</p>
            <button onClick={props.exit}>خروج</button>
            <p className={classes.Countdown}>إغلاق بعد &nbsp; {props.countdown}</p>
        </div>
    );
}
export default successScreen;

