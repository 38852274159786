const LOCAL = {
    verify: {
        ar: 'تأكيد',
        en: 'Verify',
    },
    resend: {
        ar: 'إرسال',
        en: 'Resend',
    },
    governorate: {
        ar: 'المحافظة',
        en: 'Governorate'
    },
    city: {
        ar: 'المدينة',
        en: 'City'
    },
    region: {
        ar: 'المنطقة',
        en: 'Region'
    },
    enter_info_message: {
        en: "Enter your personal information to apply to ",
        ar: "ادخل بياناتك الشخصية للتقديم على "
    },
    submit: {
        en: "Submit",
        ar: 'تأكيد'
    }

};

export default LOCAL;
