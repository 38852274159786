import React, { Component } from 'react';
import Header from '../Header/Header';
import classes from './Complaint.module.css';
import sendImage from '../../../assets/send_white.png'
import axios from 'axios';
import Loader from '../../../components/UI/Loader/Loader';
import SuccessScreen from '../SuccessScreen/SuccessScreen';
import TextareaAutosize from 'react-textarea-autosize';




class Complaint extends Component {

    state = {
        // Fill from the settings
        company_name: '',
        company_logo: '',
        verfied_phone_numbers: [],

        fb_id: null,
        platform: 'facebook',
        loading: false,
        skipCountdown: 4,
        bot: null,
        error: false,
        governorates: [],
        branches: [],//["اخميم", "ارمنت", "اسنا", "الابراهيمية", "البلينا", "الحسينية", "المنشأه", "الوقف", "ببا", "سمالوط", "طهطا", "قوص", "كوم امبو", "مغاغة", "ملوي", "منيا القمح", "ناصر", "نجع حمادي", "ههيا",],
        phoneVerified:false,
        phoneValid:true,
        nationalIdValid: false,
        formSubmitted: false,
        hide_other_branch: 'd-none',
        type: null,
        
        arabic_numbers: {
            '٠': '0',
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9'
        },

        values: {
            form_id: 3,
            type_id: 3,
            type_en: 'Complaint',
            type_ar: 'طلب تمويل',
            name: '',
            phone: '',
            national_id: '',
            governorate_id: '',
            branch_id: '',
            other_branch: '',
            complaint: '',
            // details: '',
            date: Date.now(),
        }
    }

    componentDidMount() {
        document.title = 'Complain';
        const queryParams = new URLSearchParams(window.location.search);
        const fb_id = queryParams.get('fb_id');
        const user_id = queryParams.get('user_id');
        const platform = queryParams.get('platform')
        const signature = queryParams.get('signature');
        const bot = queryParams.get('bot');
        const type = queryParams.get('type');
        this.setState({ fb_id: fb_id, platform: platform, bot: bot, type: type });
        axios.get(process.env.REACT_APP_API_URL + '/governorates/get?type=' + type).then((response) => {
            this.setState({ governorates: response.data.data })
        }).catch(error => { });
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.get(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/settings/get/' + user_id + '?signature=' + signature).then((response) => {
            this.setState({ company_name: response.data.data.settings.account_name, company_logo: response.data.data.settings.logo, verfied_phone_numbers: response.data.data.verfied_numbers})
        }).catch(error => { });
    }

    onInputChanged = (event) => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        })
        if(event.target.name === 'governorate_id'){
            axios.get(process.env.REACT_APP_API_URL + '/branches/get/?governorate_id=' + event.target.value).then((response) => {
                response.data.data.push({
                    governorate_id: 0,
                    id: 0,
                    city_ar: "آخر ...",
                    city_en: "Others",
                    name_ar: "أخرى ...",
                    name_en: "Others"
                });
                this.setState({ branches: response.data.data })
            }).catch(error => { });
        }
        if(event.target.name === 'phone'){
            let numbers = event.target.value.split("");
            let phone_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                phone_str += element;
            });
            this.setState({
                values: {
                    ...this.state.values,
                    phone: phone_str
                }
            })
            if(this.state.verfied_phone_numbers.includes(event.target.value)){
                this.setState({phoneVerified: true});
            }else{
                this.setState({phoneVerified: false});
            }
        }
        if(event.target.name === 'national_id'){
            let numbers = event.target.value.split("");
            let national_id_str = '';
            numbers.forEach(element => {
                if (element in this.state.arabic_numbers)
                    element = this.state.arabic_numbers[element];
                    national_id_str += element;
            });
            this.setState({
                values: {
                    ...this.state.values,
                    national_id: national_id_str
                }
            })
            if(event.target.value.length !== 14 ||  !/^\d+$/.test(event.target.value)){            
                this.setState({nationalIdValid:false});
            }else{
                this.setState({nationalIdValid:true});
            }
        }
        if(event.target.name === 'branch_id'){
            if(event.target.value === '0') 
                this.setState({values: {...this.state.values, branch_id: undefined}, hide_other_branch: ''});
            else
                this.setState({hide_other_branch: 'd-none'});
        }
    }

    formSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({phoneValid:true});
        if(!this.state.values.phone.startsWith('01') || this.state.values.phone.length !== 11 ||  !/^\d+$/.test(this.state.values.phone)){            
            this.setState({phoneValid:false});
            return;
        }
        if(this.state.values.national_id.length !== 14 ||  !/^\d+$/.test(this.state.values.national_id)){            
            this.setState({nationalIdValid:false});
            return;
        }
        this.setState({ loading: true });
        
        // TODO: Remove Whatsapp Demo for Tamweely
        let TAMWEELY_WHATSAPP = '';
        if(this.state.fb_id.charAt(0) == " "){
            TAMWEELY_WHATSAPP = '/demo/whatsapp';
        }
        axios.post(process.env.REACT_APP_API_URL + TAMWEELY_WHATSAPP + '/applications/create', {
            fb_id: this.state.fb_id,
            type: this.state.type,
            bot_name: this.state.bot,
            ...this.state.values
        }).then((res) => {
            this.setState({ loading: false, formSubmitted: true });
            let skipInterval = setInterval(
                () => {
                    if (this.state.skipCountdown <= 1) {
                        clearInterval(skipInterval);
                        window.closeWebView();
                    }
                    else {
                        this.setState((prevState) => ({
                            skipCountdown: prevState.skipCountdown - 1
                        }));
                    }
                },
                1000);

        }).catch(error => {
            this.setState({ loading: false, error: true });
        })
    }

    render() {
        return (
            <div className={classes.Container}>
                {this.state.loading ? <Loader /> :
                    this.state.formSubmitted ?
                        <div>
                            <SuccessScreen platform={this.state.platform} countdown={this.state.skipCountdown} exit={window.closeWebView} messages={["النموذج اتبعت بنجاح", "هيتم النظر فيها والتواصل معاك في اقرب وقت"]} />
                        </div> :
                        <React.Fragment>
                            <header className="container">
                                <Header title={'نموذج تقديم شكوى "' + this.state.company_name + '" '}  company_logo={this.state.company_logo}/>
                            </header>
                            {this.state.error && <p className={classes.Error}>حدث خطأ حاول مره اخري</p>}

                            <form id="application"
                                onSubmit={this.formSubmitHandler} >
                                <div className={`container ${classes.form}`}>



                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} value={this.state.values.name} placeholder="الاسم" required name="name" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control " + (this.state.phoneVerified ? "is-valid" : "" )} onChange={this.onInputChanged} maxLength='14' value={this.state.values.phone} type='tel' placeholder="رقم التليفون" required name="phone" />
                                        </div>
                                    </div>
                                    {!this.state.phoneValid && <p style={{color:'Red'}}>رقم التليفون غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <input className={"form-control " + (this.state.nationalIdValid ? "is-valid" : "" )} onChange={this.onInputChanged} maxLength='14' value={this.state.values.national_id} type='number' placeholder="الرقم القومي" required name="national_id" />
                                        </div>
                                    </div>
                                    {this.state.values.national_id && !this.state.nationalIdValid && <p style={{color:'Red'}}>الرقم القومي غير صحيح</p>}

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.governorate_id} name='governorate_id'>
                                                <option value="" disabled >المحافظة</option>
                                                {this.state.governorates.map((governorate) => {
                                                    return <option key={governorate.name_ar} value={governorate.id}>{governorate.name_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <select onChange={this.onInputChanged} required value={this.state.values.branch_id} name='branch_id'>
                                                <option value="" disabled >الفرع</option>
                                                {this.state.branches.map((branch) => {
                                                    return <option key={branch.name_ar} value={branch.id}>فرع {branch.city_ar}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"row " + this.state.hide_other_branch }>
                                        <div className="col-12 py-2">
                                            <input onChange={this.onInputChanged} maxLength='14' value={this.state.values.other_branch} type='text' placeholder="اسم الفرع" name="other_branch" />
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize value={this.state.values.details} onChange={this.onInputChanged} placeholder="ملاحظات" name="details" />
                                        </div>
                                    </div> */}


                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <TextareaAutosize value={this.state.values.complaint} onChange={this.onInputChanged} placeholder="الشكوي" name="complaint" />
                                        </div>
                                    </div>

                                </div>
                                <footer>
                                    <button type="submit" className={classes.Submit} >
                                        <img src={sendImage} alt="submit" />تأكيد</button>
                                </footer>
                            </form>
                        </React.Fragment>
                }
            </div >
        )
    }

}


export default Complaint;