import React, { Component } from 'react';
import classes from './Location.module.css';
import Loader from '../../components/UI/Loader/Loader'
import Geocode from "react-geocode";
import axios from 'axios';

class location extends Component {

    state = {
        longitude: '',
        latitude: '',
        address: '',
        fb_id: '',
        search: '',
        language: 'ar',
        loading: true,
        error: false,
        errorMessage: '',
        locationDetected: false
    }

    messages = {
        detecting: '.جاري تحديد موقعك',
        detected: '.تم تحديد موقعك بنجاح',
        unable: '.لم نتمكن من تحديد موقعك',
        enable: '.برجاء تفعيل خاصية مشاركة الموقع',
        notSupported: '.المتصفح لا يدعم خاصية تحديد الموقع',
        denied: '.تم رفض مشاركة تحديد الموقع',
        unavailable: '.بيانات الموقع غير متوفرة',
        timeout: '.انتهت مهلة تحديد الموقع',
        error: '.حدث خطأ فى تحديد الموقع'
    }

    messagesEn = {
        detecting: 'Detecting your location',
        detected: 'Location detected successfully',
        unable: 'Unable to detect your location',
        enable: 'Please enable location sharing on your device.',
        notSupported: 'Geolocation is not supported by this browser',
        denied: 'Request denied for location services.',
        unavailable: 'Location information is unavailable.',
        timeout: 'The request to get user location timed out.',
        error: 'An unknown error occurred.'
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search)
        const fbId = queryParams.get('fb_id');
        const language = queryParams.get('lang');
        const search = queryParams.get('search');
        const type = queryParams.get('type');
        this.setState({ fb_id: fbId, search: search, language: language, type: type });
        if (language && language.toLowerCase() === 'en') {
            this.messages = { ...this.messagesEn };
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.locationDetectSuccessHandler, this.locationDetectErrorHandler);
        } else {
            const message = this.messages.notSupported;
            this.setState({ error: true, loading: false, message: message });
        }
    }

    locationDetectSuccessHandler = (position) => {
        this.setState({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
            error: false,
            locationDetected: true,
            loading: false
        });
        this.getUserLocaiton();
    }

    getUserLocaiton = () => {
        Geocode.setApiKey("AIzaSyDtd7wB5qYyjWxR-OM24CY-2K7cTAwMinE");
        Geocode.setLanguage("en");
        Geocode.setRegion("eg");
        Geocode.fromLatLng(this.state.latitude, this.state.longitude).then(
            response => {
                const address = response.results[0].formatted_address;
                this.sendLocation(address);
            },
            error => {
                this.sendLocation("");
            }
        );

    }

    sendLocation = (address) => {
        axios.post('/user/save-location', {
            long: this.state.longitude,
            lat: this.state.latitude,
            address: address,
            search: this.state.search,
            type: this.state.type,
            fb_id: this.state.fb_id,
            language: this.state.language
        }).then((response) => {
            setTimeout(() => {
                window.closeWebView();
            }, 1000);
        }).catch((error) => {
            setTimeout(() => {
                window.closeWebView();
            }, 1000);
        })
    }

    locationDetectErrorHandler = (error) => {
        let message = '';
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = this.messages.denied;
                break;
            case error.POSITION_UNAVAILABLE:
                message = this.messages.unavailable;
                break;
            case error.TIMEOUT:
                message = this.messages.timeout;
                break;
            default:
                message = this.messages.error;
                break;
        }
        this.setState({ error: true, errorMessage: message, loading: false });
    }

    render() {
        let main = this.messages.detecting;
        if (this.state.error) {
            main = this.state.errorMessage;
        }
        if (this.state.locationDetected) {
            main = this.messages.detected;
        }
        return (
            <div className={classes.Container}>
                <h5>{main}</h5>
                <p>{this.messages.enable}</p>
                {this.state.error ? (<a target="_blank" rel="noopener noreferrer"
                    onClick={() => { window.closeWebView() }} href={window.location.href} >Try again</a>) : null}
                {this.state.loading ? <div className={classes.Loader}><Loader /></div> : null}
            </div>
        );
    }
}

export default location;