import React from 'react';
import classes from './FadeIn.module.css';
import { connect } from 'react-redux';

const fadeIn = (props) => {
    const isEnglish = props.language === "en";
    return (
        <div style={{ direction: isEnglish ? "ltr" : "rtl", textAlign: isEnglish ? "left" : "right" }}
            className={classes.FadeIn}>
            {props.children}
        </div>);
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps)(fadeIn);