import React from 'react';
import classes from './FailureScreen.module.css';
import sallyImage from '../../assets/sally_fail.png';

const failureScreen = (props) => {
    return (
        <div className={classes.Container}>
            <img src={sallyImage} alt="Sally" width="220" />
            <p>حصلت مشكلة وانت بتبعت النموذج.</p>
            <div className={classes.Options}>
                <p>ممكن تجرب تانى؟</p>
                <button onClick={props.tryAgain}>اه</button>
                <button onClick={props.exit}>لا</button>
            </div>
        </div>
    );
}
export default failureScreen;