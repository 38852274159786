import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import './AutoComplete.css';


class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: []
        };
    }

    getSuggestions(value) {

        let suggestions = [];
        suggestions.push(...this.props.options.filter(language => language.toLowerCase() === (value.toLowerCase())));

        suggestions.push(...this.props.options.filter(
            language => language.toLowerCase().startsWith(value.toLowerCase()) && !suggestions.includes(language)));

        suggestions.push(...this.props.options.filter(
            language => language.toLowerCase().includes(value.toLowerCase()) && !suggestions.includes(language)));
            
        return suggestions;
    }

    getSuggestionValue(suggestion) {
        return suggestion;
    }

    renderSuggestion(suggestion) {
        return (<span>{suggestion}</span>);
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
        this.props.onChange(newValue);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange
        };
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps} />
        );
    }
}

export default AutoComplete;
