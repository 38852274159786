import React from 'react';
import './Input.module.css';
import BlueButton from '../UI/BlueButton/BlueButton';
import VerifiedBadge from '../UI/VerifiedBadge/VerifiedBadge';
import AutoComplete from '../UI/AutoComplete/AutoComplete';
import Checkbox from '../UI/Checkbox/Checkbox';
import RadioOptions from '../UI/RadioOptions/RadioOptions';
import Select from '../UI/Select/Select';
import Time from '../UI/DateTime/DateTime';
import { connect } from 'react-redux';
import local from '../../localization/local';
import classes from  './Input.module.css';

const input = (props) => {
    let input = null;

    props.element.inputChoices = props.element[props.language + "_inputChoices"];

    let inputProps = {
        required: props.element.inputRequired,
        name: props.element.inputName,
        placeholder: props.element[props.language + "_inputLabel"].replace(/^\w/, c => c.toUpperCase()),
        onChange: props.changed.bind(this, props.element),
        type: props.element.type,
        value: props.value
    }

    if (inputProps.name === "national_id") {
        inputProps.type = "id"
    }

    switch (inputProps.type) {
        case "text":
        case "name":
        case "number":
        case "email":
            input = (<input {...inputProps} />);
            break;

        case "id":
            input = (
                <input {...inputProps} type="number"
                    onInput={(event) => { if (event.target.value.length > 14) event.target.value = event.target.value.slice(0, 14); }} />);
            break;

        case "time":
        case 'date':
            input = <Time inputProps={inputProps} />;
            break;

        case "textarea":
            input = (<textarea {...inputProps} rows="4" />);
            break;

        case "checkbox":
            input = (
                <Checkbox
                    name={inputProps.name}
                    onChange={inputProps.onChange}
                    placeholder={inputProps.placeholder} />
            );
            break;

        case "radio":
            input = (
                <RadioOptions
                    onChange={inputProps.onChange}
                    placeholder={inputProps.placeholder}
                    element={props.element} />
            )
            break;


        case "select":
            input = (<Select element={props.element} inputProps={inputProps} />);
            break;

        case "location":
            input = (
                <div className="d-flex">
                    <div className='w-100'>
                        <AutoComplete
                            onChange={props.changed.bind(this, props.element, "governorate")}
                            options={props.governments}
                            placeholder={local.governorate[props.language]} />
                    </div>
                    &nbsp;
                    <div className='w-100'>
                        <AutoComplete options={props.cities}
                            placeholder={local.city[props.language]}
                            onChange={props.changed.bind(this, props.element, "city")} />
                    </div>
                    &nbsp;
                    <div className='w-100'>
                        <input type="text"
                            placeholder={local.region[props.language]}
                            onChange={props.changed.bind(this, props.element, "region")} />
                    </div>
                </div>
            );
            break;

        case "phone":
            const verify = local.verify[props.language];
            const resend = local.resend[props.language];
            input = (
                <React.Fragment>
                    <div className="d-flex">
                        <input
                            maxLength="14"
                            disabled={props.countDown > 0}
                            style={{ borderColor: props.validPhone ? "" : "#F03D25" }}
                            {...inputProps}
                            type="tel"
                        />
                        {!props.phoneVerified ?
                            <React.Fragment>
                                <span>&nbsp;</span>
                                <BlueButton
                                    disabled={props.countDown > 0}
                                    value={props.countDown <= 0 ? props.codeSent ? resend : verify : `${resend} ${getFormattedTime(props.countDown)}`}
                                    clicked={props.phoneVerify.bind(this, props.element)} />
                            </React.Fragment>
                            :
                            <VerifiedBadge color='#25D366' />
                        }
                    </div>

                    {(props.codeSent && !props.phoneVerified) ? (
                        <div className="d-flex pt-3">
                            <input
                                type="text"
                                value={props.vCode}
                                className="w-100"
                                placeholder="Verification code"
                                onChange={props.codeChanged}
                                style={{ borderColor: props.validCode ? "" : "#F03D25" }}
                            />
                            <span>&nbsp;</span>
                            <BlueButton
                                value={verify}
                                clicked={props.codeVerify.bind(this, props.element)} />
                        </div>) : null
                    }
                </React.Fragment>
            )
            break;
        default:
            input = null;
            break;
    }
    return (
        <div className={`row ${classes.Input}`}>
            <div className="col-12 py-2">
                {input}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

const getFormattedTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remSeconds = seconds - (minutes * 60);
    return `${minutes}:${remSeconds < 10 ? '0' : ''}${remSeconds}`
}


export default connect(mapStateToProps)(input);