import React from 'react';
import classes from './RadioOptions.module.css';

const radioOption = (props) => {
    return (
        <React.Fragment>
            <label className={classes.Label}>{props.placeholder}</label>
            {listRadioOptions(props.element, props.onChange)}
        </React.Fragment>
    );
}

const listRadioOptions = (element, onChange) => {
    return element.inputChoices.map((option, index) => {
        return (
            <div key={option}>
                <label className={classes.Radio}>
                    <input
                        required={index === 0 ? element.inputRequired : false}
                        name={element.inputName}
                        value={option}
                        onChange={onChange}
                        type="radio"
                    />
                    <span></span>
                    {option}
                </label>
            </div>
        );
    })
}
export default radioOption;