import React from 'react';
import classes from './Switch.module.css';
import { connect } from 'react-redux';

const switchComponent = (props) => {
    return (
        <div className={classes.Container}>
            <label className={classes.Switch}>
                <input type="checkbox" onChange={props.onChange} />
                <span className={classes.Slider}
                    style={{ textAlign: props.language === "ar" ? "right" : "left" }}>{props.language === "ar" ? "عربى" : "En"}</span>
            </label>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps)(switchComponent);