import React, { Component } from 'react';
import classes from './DateTime.module.css'
import { connect } from 'react-redux';

class DateTime extends Component {

    state = {
        clicked: false,
    }

    render() {
        return (
            <div className={classes.Time}>
                <input {...this.props.inputProps}
                    style={{ textAlign: this.props.language === 'en' ? 'left' : 'right' }}
                    onClick={() => { this.setState({ clicked: true }) }}
                    onInput={(event) => { if (!event.target.value) { this.setState({ clicked: false }) } }}
                    onBlur={(event) => { this.setState({ clicked: event.target.value.length !== 0 }) }}
                />
                {!this.state.clicked ?
                    <span>{this.props.inputProps.placeholder}</span>
                    : null}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps)(DateTime);