import React from 'react';
import classes from './Checkbox.module.css'

const checkbox = (props) => {
    return (
        <label className={classes.Checkbox}>
            <input
                name={props.name}
                onChange={props.onChange}
                type="checkbox"
            />
            <span></span>
            <p>{props.placeholder}</p>
        </label>
    );
}

export default checkbox;