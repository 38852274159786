import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import classes from './FacebookLogin.module.css';

class Login extends Component {
    responseFacebook = (response) => {
        console.log(response);

        this.setState({
            isLoggedIn: true,
            userId: response.userID,
            name: response.name,
            email: response.email,
            token: response.accessToken,
            picture: response.picture.data.url,
        })
    }

    state = {
        isLoggedIn: false
    }

    render() {

        let login = null;

        if (this.state.isLoggedIn) {
            login = (
                <div>
                    <img className={classes.Picture} alt="Profile" src={this.state.picture} />
                    <p>Name: {this.state.name}</p>
                    <p>Email: {this.state.email}</p>
                    <p>user Id : {this.state.userId}</p>
                </div>
            )
        }
        else {
            login = (
                <FacebookLogin
                    appId="329837314463236"
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile"
                    callback={this.responseFacebook}
                />
            );
        }

        return <div className={classes.Container}>{login}</div>;
    }

}

export default Login;