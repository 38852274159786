import React, { Component } from 'react';
import ApplicationSubmission from './ApplicationSubmission/ApplicationSubmission';



class Demo extends Component {


    

    render() {
        let form = null;
        const queryParams = new URLSearchParams(this.props.location.search);
        const type = queryParams.get('type');
        switch (type) {
            case 'submission':
                form = <ApplicationSubmission />
                break;
            default:
                break;
        }
        return (form);
    }

}


export default Demo;