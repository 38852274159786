import React from 'react';
import classes from './Header.module.css';
import valuLogo from '../../../assets/valu-logo.png'
import local from '../../../localization/local';
import { connect } from 'react-redux';

const header = (props) => {

    const isEnglish = props.language === "en";
    let headline = local.enter_info_message[props.language];


    // ValU part start
    let isValu = props.bank === 'ValU' || props.bank === 'ڤاليو'

    if (isValu) {
        headline += isEnglish ? ' ValU' : ' ڤاليو ';
    }
    //ValU part end

    else {
        switch (props.bot) {
            case 'sally':
                headline += isEnglish ? "cedit card " : 'البطاقة الإئتمانية ';
                headline += props.loan_type;
                headline += " " + props.provider;
                break;

            case 'sara':
                headline += props.loan_type;
                headline += " " + props.collateral_types;
                break;
            default:
                break;
        }

        headline += isEnglish ? " from " : " من "
        headline += props.bank;
    }
    return (
        <div className="row pt-3 pb-2 px-2">
            <div className={`col-9 px-2 ${classes.headline}`}>
                <h2>{headline}</h2>
            </div>
            <div className="col-3 p-0 m-auto">
                <img className="w-100" alt="logo" src={isValu ? valuLogo : props.image} />
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        language: state.language,
        bot: state.bot
    }
}

export default connect(mapStateToProps)(header); 