import React from 'react';
import classes from './Header.module.css';
// import logo from '../../../assets/tamweely.jpeg'

const header = (props) => {
    
    return (
        <div className="row pt-3 pb-2 px-2">
            <div className={`col-9 px-2 ${classes.headline}`}>
                <h2>{props.title}</h2>
            </div>
            <div className="col-3 p-0 m-auto">
                {/* <img className="w-100" alt="logo" src={logo} /> */}
                <img className="w-100" alt="logo" src={props.company_logo} />
            </div>
        </div>
    );
}

export default header; 